export default class Helpers {

    static deepCopy(data) {
        return JSON.parse(JSON.stringify(data));
    }

    static productListTreeToList(tree) {
        var resList = [];
        if (!tree) return resList;
        tree.forEach(function (item) {
            var children = item.children;
            delete item.children;
            resList.push(item);
            if (children) {
                resList = resList.concat(Helpers.productListTreeToList(children));
            }
        });

        return resList;
    }

    static productListToTree(data) {
        var aTree = [];
        var parentLevel = {};
        var levelLength = {
            0: 2,
            1: 4,
            2: 6,
            3: 8 // to confirm
        };
        for (var i = 0; i < 4; i++) {
            if (!parentLevel[i]) {
                parentLevel[i] = [];
            }
            data.forEach(function (item) {
                if (item.id.replace(/\./g, "").length !== levelLength[i]) {
                    return;
                }
                item.children = [];
                parentLevel[i].push(item);
                if (i === 0) {
                    aTree.push(item);
                } else {
                    var hasParent = false;
                    for (var j = i - 1; j >= 0; j--) {
                        hasParent = parentLevel[j].some(function (parentNode) {
                            if (item.id.replace(/\./g, "").startsWith(parentNode.id.replace(/\./g, ""))) {
                                parentNode.children.push(item);
                                return true;
                            }
                        });
                        if (hasParent) {
                            break;
                        }
                    }
                    if (!hasParent) {
                        aTree.push(item); // put orphans on top level
                    }
                }

            });
        }
        Helpers.sortTree(aTree);
        return aTree;
    }

    static sortTree(tree) {
        tree.sort(function (a, b) {
            return a.id.replace(/\./g, "").localeCompare(b.id.replace(/\./g, ""));
        });
        tree.forEach(function (item) {
            if (!item.children) return;
            Helpers.sortTree(item.children);
        });
    }
}
