import * as moment from "moment";
import * as _ from "lodash";
import jsonLogic from 'json-logic-extension';

window.moment = moment;

/**
 * Controller for displaying File lists for different roles, including 'applicant'
 */
app.filter('filterBy', function () {
    return function (items, word) {
        var filtered = [];
        var wordMatch = new RegExp(word, 'i');
        angular.forEach(items, function (item) {
            /**
             * make sure the correct property is matched
             */
            if (wordMatch.test(item.meta_data)) {
                filtered.push(item);
            }
        });
        return filtered;
    };
}).controller('PartBController', ['$scope', '$rootScope', 'Backend', 'SiteConfiguration', '$location', 'Helpers', '$element', '$q', '$http', '$window', 'cfg',
    '$translate', '$timeout', '$translatePartialLoader', '$sce', 'Notification', 'FormioUtils',
    function ($scope, $rootScope, Backend, SiteConfiguration, $location, Helpers, $element, $q, $http, $window, cfg,
              $translate, $timeout, $translatePartialLoader, $sce, Notification, FormioUtils) {
        Object.keys(jsonLogic.customOperations).forEach(function (k) {
            FormioUtils.jsonLogic.add_operation(k, jsonLogic.customOperations[k]);
        });
        this.applicant_files = {'list': [], 'count': 0, 'page': 0};
        this.file_document_list = {};
        this.file_certificate_list = {};
        this.assignee = null;
        this.page = 'list';
        this.filter_by = ''; // freetext filter
        this.defaultRoleCountStatus = 'filepending';
        this.filter = {status: this.defaultRoleCountStatus, fromDate: null, toDate: null}; // filter by field values
        this.showLegend = false;
        this.order_by = 'startDate';
        this.reverse = true;
        this.form_data = {};
        this.file = null;
        this.has_B6 = false;
        this.isB6REsolved = false;
        this.showDocsList = true;
        this.docsLoaded = false;
        // this.confirm_and_close = false;
        this.show_processing = false;
        this.busy = false;
        // this.file_list_merged = false;
        // this.role_count = {};
        this.counter = 0;
        this.error_message_keys = {};
        this.GLOBALS = Helpers.GLOBALS;
        this.isFilePendingInRole = false;
        this.isConclusionProposedDateOfWithdrawalPresent = false;
        this.loaded_services = [];
        this.roleToRestartAfter = 'start';
        this.responsiveFilesListSortVisible = false;
        this.responsiveFilesListSearchVisible = false;
        this.showBotsInMenu = false;
        this.visibleRolesCount = 0;
        this.menuSelectedRole = {name: '', count: 0};
        var self = this;

        /**
         * Next 3 parameters are ugly because we need to tell elevatezoom component to refresh itself.
         * If there is no change in scope, it will break.
         * @type {number}
         */
        this.activeSlide = 0;
        this.currentImageUrl = '';
        this.allImages = [];
        this.isApplicantProcessing = false;
        this.sendBackReasons = null;

        angular.element(document).ready(function () {
            var bodyEl = angular.element(document.querySelector('body'));
            bodyEl.addClass('ready-now');
        });
        /**
         * new systema
         */
        this.initController = function (size) {
            addEventListener("PRINTOUT", this.printAnyForm.bind(this));
            if (CURRENT_LANGUAGE) {
                this.CURRENT_LANGUAGE = CURRENT_LANGUAGE;
                moment.locale(CURRENT_LANGUAGE);
            }
            self.size = size;

            Backend.getServices({isPartB: true}).then(function (res) {
                Array.prototype.push.apply(self.loaded_services, res);
                var process_id = Helpers.getUriSearchParamValue("process_id");
                var service_id = Helpers.getUriSearchParamValue("service");
                if (!service_id) {
                    service_id = self.getUriParamValue('service');
                }
                if (!process_id) {
                    process_id = self.getUriParamValue('process_id');
                }
                self.setUriParam('service', 'service_id', service_id, self.loaded_services, true);
                if (!process_id) {
                    self.initDateRangeFilter();
                    self.chooseService();
                    return;
                }
                self.isApplicantProcessing = true;
                /* self.service should be available from here on */
                self.getServiceRoles().then(function (role_list) {
                    /* add service translations */
                    $translatePartialLoader.addPart('general');
                    $translatePartialLoader.addPart(self.service.service_id);
                    Backend.getProcess(process_id).then(function (process) {
                        var latestTask = Helpers.getLatestTask(process);
                        self.service_role_list = role_list.filter(function (role) {
                            return role.id === latestTask.camundaName;
                        });
                        const selectedRole = self.getUriParamValue('role');
                        self.role = self.service_role_list.filter(function (role) {
                            return role.id === selectedRole;
                        })[0];
                        if (!self.role) {
                            self.service_role_list.each((r) => {
                                if (r.type == "revision") {
                                    self.role = r;

                                    return;
                                }
                            });

                            if (!self.role) {
                                self.service_role_list.each((r) => {
                                    if (r.type != "bot") {
                                        self.role = r;

                                        return;
                                    }
                                });
                            }

                            if (self.role) {
                                self.role = self.service_role_list[0];
                            }
                        }
                        try {
                            document.querySelector("#reportrange").remove();
                        } catch (e) {
                        }
                        Helpers.setFileState(process);
                        process.service_name = self.service.name;
                        process.service_icon_url = self.service.get_icon_url;
                        self.showFileProcessing(process);
                    });
                });

            });

        };

        this.initDateRangeFilter = function () {
            var cb = function (start, end) {
                if (start && !(start instanceof moment)) { // in case of clearing
                    start = end = null;
                }
                self.filter.fromDate = start && start.format();
                self.filter.toDate = end && end.format();
                self.loadRoleFiles();
                if (start == null && end == null) {
                    $('#reportrange span').html('');
                } else {
                    $('#reportrange span').html(start.format('LL') + ' - ' + end.format('LL'));
                }
            }

            var ranges = {};
            [
                {name: $translate.instant('Today'), value: [moment(), moment()]},
                {
                    name: $translate.instant('Yesterday'),
                    value: [moment().subtract(1, 'days'), moment().subtract(1, 'days')]
                },
                {name: $translate.instant('Last 7 Days'), value: [moment().subtract(6, 'days'), moment()]},
                {name: $translate.instant('Last 30 Days'), value: [moment().subtract(29, 'days'), moment()]},
                {name: $translate.instant('This Month'), value: [moment().startOf('month'), moment().endOf('month')]},
                {
                    name: $translate.instant('Last Month'),
                    value: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                }
            ].forEach(function (range) {
                ranges[range.name] = range.value;
            });

            $('#reportrange').on('cancel.daterangepicker', cb);

            var opensDir = LANG_DIRECTION === 'rtl' ? 'right' : 'left';
            var dtpkrng = $('#reportrange').daterangepicker({
                locale: {
                    cancelLabel: $translate.instant('Clear'),
                    applyLabel: $translate.instant('Apply')
                },
                showCustomRangeLabel: false,
                alwaysShowCalendars: true,
                linkedCalendars: false,
                opens: opensDir,
                ranges: ranges,
                isToday: function (date) {
                    // Highlight today's date using a custom class
                    return date.isSame(moment(), 'day') ? 'today' : false;
                },
                maxDate: moment()
            }, cb);
            dtpkrng.on("show.daterangepicker", function (ev, picker) {
                picker.container[0].classList.add("visible-calendars");
            });
            dtpkrng.on("hide.daterangepicker", function (ev, picker) {
                picker.container[0].classList.remove("visible-calendars");
            });
        }

        this.chooseService = function (resetRole) {
            self.page = 'list';
            var service_id = self.getUriParamValue('service');

            /* service is set by select */
            if (self.service !== undefined) {
                service_id = self.service.service_id;
            }
            if (resetRole === true) {
                $location.search('role', null);
                self.role = null;
            }
            self.setUriParam('service', 'service_id', service_id, self.loaded_services, true);

            /* self.service should be available from here on */
            self.getServiceRoles().then(function (role_list) {
                /* add service translations */
                $translatePartialLoader.addPart('general');
                $translatePartialLoader.addPart(self.service.service_id);

                let defaultRole = false;
                if (role_list) {
                    // Try to pick a revision role
                    role_list.some(function (r) {
                        if (r.type == "revision") {
                            defaultRole = r.id;

                            return true;
                        }
                    });

                    // If nothing picked, got for first non-bot
                    if (!defaultRole) {
                        role_list.some(function (r) {
                            if (r.type != "bot") {
                                defaultRole = r.id;

                                return true;
                            }
                        });
                    }
                }

                const selectedRole = self.getUriParamValue('role') || (self.role ? self.role.id : defaultRole);
                if (selectedRole) {
                    role_list.some(function (role) {
                        if (role.id !== selectedRole) return;
                        // First role on the list selected if we cannot determine selection from query params
                        self.role = selectedRole;
                        self.menuSelectedRole.name = role.name;
                        self.menuSelectedRole.count = role.count;
                        self.setUriParam('role', 'id', selectedRole, role_list, true);
                        return true;
                    });
                }
                if (self.role) {
                    self.menuSelectedRole.name = self.role.name;
                    self.menuSelectedRole.count = self.role.count;
                    self.loadRoleFiles({skipRoleListLoading: true});
                } else {
                    /* no roles */
                    self.filter.role_name = 'all';
                    self.applicant_files = {'list': [], 'count': 0, 'page': 0};
                }
                //in case of no role selected, no files are loaded
            });
        };

        this.menuSelectedRoleText = function (selectedRole) {
            return `<span class="active-role-name">${$translate.instant(selectedRole.name)}</span><span class="active-role-files-count">${selectedRole.count}</span>`;
        };

        this.setUriParam = function (scope_param, scope_param_value, value, default_list, add_to_uri) {
            if (value === null && default_list.length) {
                self[scope_param] = default_list[0];
            } else {
                angular.forEach(default_list, function (s) {
                    if (value == s[scope_param_value]) {
                        self[scope_param] = s;
                    }
                });
            }
            if (self[scope_param] && add_to_uri) {
                /*set selected service as url param*/
                var val = self[scope_param][scope_param_value];
                $location.search(scope_param, val);
            }
        };

        this.getUriParamValue = function (key) {
            var return_value = null;
            var params = $location.search();
            if (params[key] !== undefined && params[key].length) {
                return_value = params[key];
            }
            return return_value;
        };
        /**
         * @param opts - options { params: {}, skipRoleListLoading: Boolean }
         * opts.params url params to be passed
         */
        this.loadRoleFiles = _.debounce(function (/* opts */) {
            var params = self.getUrlParams(), opts = Object(arguments[0]);
            if (opts.params && typeof opts.params === 'object') Object.assign(params, opts.params);
            Helpers.fileStates.closed = $translate.instant('Validated');

            if (self.service && self.role) {
                const req = JSON.stringify({service_id: self.service.service_id, role_id: self.role.id, params});
                if (self.previousReqFileList !== req) {
                    Backend.getFileListInProcessPage(self.service.service_id, self.role.id, params).then(function (ret) {
                        if (self.role && ret.count !== undefined) {
                            self.role.count = ret.count;
                        }
                        angular.forEach(ret['list'], function (f) {
                            Helpers.setFileState(f);
                            f.service_name = $translate.instant(self.service.name);
                            f.service_icon_url = self.service.get_icon_url;
                        });
                        self.applicant_files = ret;
                        self.filter.role_name = ret['role'];
                    });
                    self.previousReqFileList = req;
                }
            }

            if (self.service && self.filter && !opts.skipRoleListLoading) {
                self.getServiceRoles();
            }
        }, 400);

        // TODO: This probably needs attention because of our schema change
        this.isFormio3Used = function (processData) {
            let result = false;
            if (!processData || !processData['tasks']) return false;
            if (!Array.isArray(processData['tasks'])) return false;
            processData['tasks'].some(function (task) {
                if (!task['formProperties']) return;
                if (!task['formProperties']['formProperties']) return;
                if (!task['formProperties']['formProperties'][0]) return;
                if (!task['formProperties']['formProperties'][0]['formioVersion']) return;
                if (task['formProperties']['formProperties'][0]['formioVersion'] == "formio3") {
                    result = true;
                } else {
                    result = false;
                }
                if (task.camundaName === (self.role && self.role.id)) {
                    return true;
                }
            });

            return result;
        }

        this.accessFile = function (file) {
            // use it for side effect of setting up isFilePendingInRole
            this.getFileFormProperties(file, self.role);

            Backend.getProcess(file.id).then((processData) => {
                if (!processData) throw new Error('Internal error. Could not retrieve process.');
                Object.assign(file, processData);

                if (self.isFormio3Used(processData)) {
                    if (this.isFilePendingInRole) {
                        window.location = '/' + (CURRENT_LANGUAGE || '') + '/part-b/edit/' + file.serviceId + '/' + self.role.id + '/' + file.id;
                    } else {
                        window.location = '/' + (CURRENT_LANGUAGE || '') + '/part-b/edit/' + file.serviceId + '/' + self.role.id + '/' + file.id + '?is_overview=1';
                    }
                    return;
                }

                window.location.hash = '';
                if (this.isFilePendingInRole) {
                    this.showFileProcessing(file);
                } else {
                    this.showFileOverview(file);
                }
            });
        }

        this.collectFilters = function () {
            var filters = {};
            if (self.filter.status && self.filter.status !== 'all') {
                filters.status = self.filter.status;
            }
            if (self.filter_by.length) {
                filters.metaData = self.filter_by;
            }

            if (self.filter.fromDate) {
                filters.fromDate = self.filter.fromDate;
            }

            if (self.filter.toDate) {
                filters.toDate = self.filter.toDate;
            }

            return Object.keys(filters).length ? filters : null;
        };

        this.getUrlParams = function () {
            var filters = self.collectFilters();
            var params = {
                page: 0,
                size: self.size,
                orderBy: self.order_by,
                sortOrder: self.reverse ? "desc" : "asc"
            };
            if (filters) {
                Object.assign(params, filters);
            }

            return params;
        };

        this.pageChanged = _.debounce(function (new_page) {
            var finalPage = new_page ? new_page - 1 : 0;
            var currentParams = this.getUrlParams();
            self.loadRoleFiles({params: {page: new_page ? new_page - 1 : 0}, skipRoleListLoading: true});
        }, 400);

        this.sortFilesBy = function (value, data_type) {
            self.reverse = (value !== null && self.order_by === value) ? !self.reverse : false;
            self.order_by = value;
            self.loadRoleFiles();
        };

        this.responsiveFilesListSortToggle = function () {
            self.responsiveFilesListSearchVisible = false;
            self.responsiveFilesListSortVisible = !this.responsiveFilesListSortVisible;
        }

        this.responsiveFilesListSearchToggle = function () {
            this.responsiveFilesListSortVisible = false;
            this.responsiveFilesListSearchVisible = !this.responsiveFilesListSearchVisible;
        }

        this.deleteFile = function (file) {
            Backend.deleteFile(file.file_id).then(function () {
                self.loadFileList();
            })
        };

        this.loadFileList = function () {
            Backend.getFileListInProcessPage(self.items_per_page, 0).then(function (ret) {
                self.applicant_files = ret;
                var service_reg = {};
                angular.forEach(self.applicant_files.list, function (file) {
                    Helpers.setFileState(file);
                    service_reg[file.service_id] = file.service_name
                });

                angular.forEach(service_reg, function (value, key) {
                    self.loaded_services.push({service_id: key, service_name: $translate.instant(value)});
                });
                self.loaded_services.sort(function (a, b) {
                    return $translate.instant(a.service_name).localeCompare($translate.instant(b.service_name));
                });
                if (!self.service && self.loaded_services) {
                    var params = $location.search();
                    if (params['service'] !== undefined && params['service'].length) {
                        angular.forEach(self.loaded_services, function (service) {
                            if (service.service_id == params['service']) {
                                self.service = service;
                            }
                        });
                    } else {
                        self.service = self.loaded_services[0];
                    }

                    self.chooseService();
                }
            });

            self.getServiceRoles();
        };

        this.statusLegendFilter = function (filestatus) {
            self.filter.status = self.filter.status === filestatus ? 'all' : filestatus;
            self.loadRoleFiles();
        };

        this.getCertificates = function (file, data) {
            self.cerificate_loading_message = "Loading certificates";
            Backend.getProcessCertificates(file.id).then(function (certificates) {
                self.file_certificate_list = Helpers.prepareDocuments(certificates, file, data, 'process_certificate');
                self.cerificate_loading_message = "No file certificates";
            }).catch(function () {
                self.cerificate_loading_message = "No file certificates";
            });
        };

        this.getDocuments = function (file, data, opts = {}) {
            self.document_loading_message = $translate.instant("Loading documents");
            if (opts.useLoader) {
                self.docsLoaded = false;
            }
            Backend.getProcessDocuments(file.id).then(function (result) {
                self.file_document_list = Helpers.prepareDocuments(result, file, data, 'document');
                if (result.length) {
                    self.document_loading_message = null;
                } else {
                    self.document_loading_message = $translate.instant("No file documents");
                }
                if (opts.useLoader) {
                    self.docsLoaded = true;
                }
            }).catch(function () {
                if (opts.useLoader) {
                    self.docsLoaded = true;
                }
                self.document_loading_message = $translate.instant("Error loading documents");
            });
        };
        this.downloadFileArchive = function (processId) {
            $window.location.href = Backend.getFileArchiveURL(self.file.id);
        }
        this.getRequiredForms = function (form_type) {
            if (self.form) {
                var form_list = [];
                angular.forEach(self.form[form_type], function (form) {
                    if (self.formHasFile(form)) {
                        form_list.push(form);
                    }
                });
                return form_list;
            }
        };

        this.gotoFileEditing = function (file) {
            self.page = 'iframe';
            self.applicationEditUrl = $sce.trustAsResourceUrl("/redirect-to-process/" + file.serviceId + "/" + file.id + '?noFrame=1');
        };

        this.formHasFile = function (form) {
            var has_file = false;
            angular.forEach(self.file_document_list, function (documents) {
                if (!has_file) {
                    angular.forEach(documents.list, function (document) {
                        if (!has_file) {
                            var key = Helpers.getFileKey(self.file, document);
                            angular.forEach(form.components, function (component) {
                                if (!has_file) {
                                    angular.forEach(component.components, function (comp) {
                                        if (!has_file && comp.key == key) {
                                            has_file = true;
                                        }
                                    });
                                }
                            });
                        }
                    });
                }
            });
            return has_file;
        };

        this.documentCount = function () {
            return Helpers.fileListCount(self.getFilteredDocumentslist());
        };

        this.certificateCount = function () {
            return Helpers.fileListCount(self.file_certificate_list);
        };

        this.otherDocumentsCount = function () {
            var certs = this.certificateCount() || 0;

            return certs + self.documentCount();
        }

        // well, not exactly list...
        this.getFilteredDocumentslist = function () {
            if (!self.has_B6 || !self.docsLoaded || !self.isB6REsolved) {
                if (!self.isB6REsolved) return {};
                return self.file_document_list || {};
            }
            var result = {};
            var b6Docs = [];
            if (!self.form || !self.form.B6) {
                return result;
            }
            self.form.B6.forEach(function (B6) {
                b6Docs = b6Docs.concat(self.getB6Documents(B6, self.form.data));
            });
            Object.keys(self.file_document_list).forEach(function (docKey) {
                var isB6Doc = self.file_document_list[docKey].list.some(function (doc) {
                    return b6Docs.some(function (b6doc) {
                        return b6doc.document_id === doc.document_id;
                    });
                });
                if (!isB6Doc) {
                    result[docKey] = self.file_document_list[docKey];
                }
            });

            return result;
        }

        this.getFormTitle = function (form) {
            return Helpers.getFormTitle(form);
        };

        this.getDocumentStatusKey = function (form) {
            var status_key = "";
            angular.forEach(form.components, function (component) {
                angular.forEach(component.components, function (comp) {
                    if (comp.label == "is_document_valid" && status_key == "") {
                        status_key = comp.key;
                    }
                })
            });
            return status_key;
        };

        /**
         * Get document/certificate name
         * @param document/certificate
         */
        this.getFileName = function (obj) {
            var key = Helpers.getFileKey(self.file, obj);
            if (key == null) {
                return "";
            }
            var title_key = key.replace("fileupload", "filetitle");
            var title = "";
            if (title_key !== null) {
                title = self.file.data[title_key];
            }
            return title;
        };

        /**
         * Returns a document/certificate confirmation value
         *
         * @param document/certificate
         */
        this.getConfirmationValue = function (document) {
            var return_value = false;
            if (self.file.confirmations[document.document_id]) {
                return_value = self.file.confirmations[document.document_id];
            }
            return return_value;
        };

        this.getFileFormProperties = function (file, role) {
            /**
             * Find the proper task from file
             */
                // search for first filepending
            var role_form_properties = [];
            self.isFilePendingInRole = false;
            angular.forEach(file.tasks, function (task) {
                if (task.camundaName == role.id) {
                    if (task.status === 'filepending' && !file.ended) {
                        self.isFilePendingInRole = true;
                    }
                    if (task.formProperties && task.formProperties.formProperties) {
                        role_form_properties = task.formProperties.formProperties;
                    }
                }
            });
            return role_form_properties;
        };

        this.getFileProcessingForm = function (file) {
            self.docsLoaded = false;
            self.file = file;
            $scope.form_url = "";
            self.form = {data: {}, confirmations: file.confirmations};

            self.form.role_name = self.role.name;
            self.loadedForms = [];
            self.isConclusionProposedDateOfWithdrawalPresent = false;

            Backend.getProcess(file.id).then(function (processData) {
                if (!processData) throw new Error('Internal error. Could not retrieve process.');
                Object.assign(self.file, processData);
                var form_properties = self.getFileFormProperties(processData, self.role);
                Backend.getRoleProcessVariables(file, self.role).then(function (file_data) {
                    self.form.data = file_data.variables;
                    self.sendBackReasons = Helpers.getRejectionReasons(self.form.data);
                    self.form.data['username'] = cfg.user;
                    $scope.username = cfg.user;
                    self.form['can_complete'] = false;

                    self.form._id = "put";
                    self.form.url = file_data.form_url;
                    self.form.submit_url = file_data.form_submit_url;
                    self.getCertificates(file, self.form.data);
                    self.getDocuments(file, self.form.data);
                    if (self.role && self.role.registrationKeys) {
                        self.form.data.context = {registrationsFilter: self.role.registrationKeys.split(",")};
                    }
                    console.log(form_properties);
                    var promises = [];
                    angular.forEach(form_properties, function (form_prop) {
                        if (form_prop.formName && form_prop.formName !== 'Form' && !Helpers.evalCanShow(form_prop.formName, {}, self.form.data)) {
                            console.log('hide form', form_prop);
                            return;
                        }
                        console.log('Fetch form ', form_prop.formId);
                        var opts = {};
                        if (form_prop.formId.endsWith('reviewwithtabs')) {
                            opts.process_id = file.id;
                        }
                        promises.push(Backend.getFormioForm(form_prop.formId, opts).then(function (form_data) {
                            self.loadedForms.push(form_prop.formType);
                            if (form_prop.formId.endsWith('reviewwithtabs') && form_data.data && form_data.data[0]) {
                                self.form.data = Object.assign(self.form.data, form_data.data[0].mycardEditGridData);
                            }
                            if (self.form[form_prop.formType] === undefined) {
                                // self.form[form_prop.formType] = Helpers.changeUploadUrl(form_data.data, self.file.id, '/certificate');
                                self.form[form_prop.formType] = Helpers.changeProcessUploadUrl(form_data.data, self.file.id, '$4');
                            } else {
                                /* all forms are in list */
                                // var temp = Helpers.changeUploadUrl(form_data.data, self.file.id, '/certificate');
                                var temp = Helpers.changeProcessUploadUrl(form_data.data, self.file.id, '$4');
                                angular.forEach(temp, function (f) {
                                    self.form[form_prop.formType].push(f);
                                });
                            }

                            if (!(["role", "conclusion"].indexOf(form_prop.formType))) {
                                self.show_processing = true;
                            }
                            if (form_prop.formType && form_prop.formType.endsWith("conclusion")) {
                                self.isConclusionProposedDateOfWithdrawalPresent = Boolean(FormioUtils.getComponent(form_data.data[0].components, 'conclusionProposedDateOfWithdrawal'));
                            }
                            self['has_' + form_prop.formType] = true;
                        }));
                    });
                    Promise.all(promises).then(function () {
                        $scope.$broadcast("scopeResolved", {skipImageRender: self.has_B6});
                        self.isB6REsolved = true;
                        self.docsLoaded = true;
                    });
                });

            }).catch(function (e) {
                alert(e);
            });
        };

        this.dumpDebug = function () {
            console.log(self);
        };

        /**
         *
         * @param b6form
         */
        this.getB6Documents = function (b6form, data) {
            // get the key name from form name
            var parts = b6form["name"].split("B6");
            var key = "fileupload" + parts[1];
            var doc_list = [];
            angular.forEach(data[key], function (doc) {
                if (angular.isObject(doc)) {
                    doc_list.push(doc);
                }
            });
            return doc_list;
        };

        this.incCounter = function () {
            self.counter += 1;
            return self.counter;
        };

        this.recordImageUrl = function (url) {
            if (!self.allImages.length) {
                self.currentImageUrl = url;
            }
            var in_list = false;
            angular.forEach(self.allImages, function (image) {
                if (image == url) {
                    in_list = true;
                }
            });
            if (!in_list) {
                self.allImages.push(url);
            }
        };

        this.prevSlide = function () {
            self.activeSlide--;
            if (self.activeSlide < 0) {
                self.activeSlide = self.allImages.length - 1;
            }
            self.currentImageUrl = self.allImages[self.activeSlide];
        };

        this.nextSlide = function () {
            self.activeSlide++;
            if (self.activeSlide >= self.allImages.length) {
                self.activeSlide = 0;
            }
            self.currentImageUrl = self.allImages[self.activeSlide];
        };

        this.showFileProcessing = function (file) {
            self.docsLoaded = false;
            self.isB6REsolved = false;
            self.file = file;
            self.page = 'process';
            self.file_history = Helpers.getFileHistory(file);
            self.has_B6 = false;
            addEventListener("REFRESH_CERTIFICATES", function () {
                self.getCertificates(self.file, self.form.data);
                self.getDocuments(self.file, self.form.data, {useLoader: true});
            });
            self.getFileProcessingForm(file);
            addEventListener("RELOAD_FORM", function (ev) {
                setTimeout(function () {
                    document.getElementById(ev.detail).click();
                }, 500);
            });
        };

        this.showFileOverview = function (file) {
            self.docsLoaded = true;
            self.isB6REsolved = true;
            self.file = file;
            self.page = 'process';
            self.file_history = Helpers.getFileHistory(file);
            self.has_B6 = false;
            self.show_processing = false;
            self.form = {data: {}, confirmations: file.confirmations};
            Backend.getRoleProcessVariables(self.file, undefined).then(function (file_data) {
                self.form.data = file_data.variables;
                self.sendBackReasons = Helpers.getRejectionReasons(self.form.data);
                self.getCertificates(file, self.form.data);
                self.getDocuments(file, self.form.data);
                Backend.getProcessCamundaPreviewForms(file.id).then(function (form_list) {
                    angular.forEach(form_list.data, function (form) {
                        Backend.getFormioForm(form.formId).then(function (result) {
                            self.form[form.formType] = result.data;
                            self.isB6REsolved = true;
                        });
                    });
                });
            });
        }

        this.downloadZip = function () {
            /**
             * generate zip from file data, documents and certificates
             */
            var data_blocks = angular.copy($('[id^=yw7-tab-999-]'));
            data_blocks.find("formio-errors").empty();
            var pure_html = ['<!DOCTYPE html><html><head><meta charset="UTF-8"><title>File Data</title></head><body>'];
            angular.forEach(data_blocks, function (block) {
                pure_html.push(block.outerHTML)
            });
            pure_html.push('</body></html>');

            var zip = new JSZip();
            zip.file("file-data.html", pure_html.join("<hr>"));

            var folder1 = zip.folder("documents");
            var folder2 = zip.folder("certificates");
            var promises = [];
            self.getImgForZip(promises, self.file_document_list, folder1);
            self.getImgForZip(promises, self.file_certificate_list, folder2);

            $q.all(promises).then(function () {
                zip.generateAsync({type: "blob"})
                    .then(function (content) {
                        saveAs(content, "applicant-file.zip");
                    });
            });
        };

        this.getImgForZip = function (promises, obj_list, folder) {
            angular.forEach(obj_list, function (obj0) {
                angular.forEach(obj0.list, function (obj) {
                    promises.push($http.get(obj.file_url, {responseType: "blob"}).then(function (resp) {
                        folder.file(obj.file_name, resp.data, {base64: true});
                    }));
                });
            });
        };

        this.showProcessingTab = function (role) {
            $('#roles-menu-dropdown').prop('checked', false);
            $location.search('role', role);
            self.role = self.getRoleFromList(role);
            self.menuSelectedRole.name = self.role.name;
            self.menuSelectedRole.count = self.role.count;
            self.loadRoleFiles();
            self.page = 'list';
        };

        this.getRoleFromList = function (role_id) {
            var role = self.service_role_list[0];
            angular.forEach(self.service_role_list, function (r) {
                if (role_id == r.id) {
                    role = r;
                }
            });
            return role;
        };

        this.showFileList = function () {
            self.page = 'list';
        };

        this.confirmSubmit = function () {
            self.form['can_complete'] = false;
            $scope.$emit('formSubmit', self.form);
        };

        this.submitRoleProcessingResult = function (status, reason) {
            self.busy = true;
            var role_name = self.form.role_name;

            /* validate forms */
            self.file_has_errors = Helpers.checkFormErrors2(self.loadedForms, $element, $scope, self.form);
            if (self.file_has_errors) {
                self.error_messages_dict = $scope.error_messages_dict;
                return;
            }

            if (status) {
                self.form.data[role_name + 'selection'] = status;
                if (reason) {
                    self.form.data[role_name + 'reason'] = reason;
                }
            }

            // when validated or rejected - always true
            self.form['can_complete'] = true;
            $scope.$emit('formSubmit', self.form);
        };

        this.toggleBotsInMenu = function() {
            var botsVisible = localStorage.getItem("partBHideBOTRoles") === '1';
            localStorageSetItemWithFullWarning("partBHideBOTRoles", botsVisible ? '0' : '1');
            dispatchEvent(new CustomEvent("showBotRolesChange"));
        };

        this.documentDownload = Helpers.documentDownload;
        this.getFileForDisplay = Helpers.getFileForDisplay;

        this.getSrcProperty = function (document) {
            var result;
            if (!document) return;
            if (!document.imageSrc && !document.url) return;
            if (document.imageSrc && typeof document.imageSrc === 'string') {
                result = document.imageSrc;
            } else {
                result = document.url;
            }
            // we use relative, cause when multiple domains premissions may cut it out
            return result.replace(/^https?:\/\/.+?\//, "");
        };

        this.trustSrc = function (src) {
            return $sce.trustAsResourceUrl(src);
        };

        $scope.$on('formSubmit', function (event, submission) {
            /* validate forms */
            if (!submission['skip_validation']) {
                self.file_has_errors = Helpers.checkFormErrors2(["role", "B6", "formvalidation"], $element, $scope, self.form);
                if (self.file_has_errors) {
                    self.error_messages_dict = $scope.error_messages_dict;
                    return;
                }
            } else {
                delete submission['skip_validation'];
            }

            //not defined is single file validation
            if (!'can_complete' in submission) {
                submission['can_complete'] = false;
            }

            var now = new Date().getTime();
            Backend.updateForm(self.form.submit_url, submission).then(function (response) {
                if (submission['can_complete']) {
                    if (self.isApplicantProcessing) {
                        window.location.href = '/';
                    } else {
                        self.chooseService();
                    }
                }
                self.busy = false;
            }).catch(function (err) {
                // server timeouts through harakiri after 120 seconds
                if ((new Date().getTime() - now) > 110000) {
                    Notification.clearAll();
                    Notification.warning({
                        message: $translate.instant("Processing of your request will takes longer than usually, please check again later"),
                        replaceMessage: true
                    });
                } else {
                    throw new Error(err);
                }
            });
        });

        /**
         *
         */
        $scope.$on('validate', function (event, data) {
            var name = event.targetScope.componentId.replace("btn", "").replace("filevalidated", "");
            self.form.data[name + 'selection'] = "filevalidated";
            self.form['can_complete'] = true;
            $scope.$emit('formSubmit', self.form);
        });

        $scope.$on('correction', function (event, data) {
            var name = event.targetScope.componentId.replace("btn", "").replace("filedecline", "");
            self.form.data[name + 'selection'] = "filedecline";
            if (self.role && self.role.type === 'revision') {
                self.form['can_complete'] = true;
                self.form['skip_validation'] = true;
                $scope.$emit('formSubmit', self.form);
            } else {
                $('#sendbacktocorrection_modal').modal({
                    keyboard: false
                })
            }

        });

        $scope.$on('reject', function (event, data) {
            var name = event.targetScope.componentId.replace("btn", "").replace("filereject", "");
            self.form.data[name + 'selection'] = "filereject";
            if (self.role && self.role.type === 'revision') {
                self.form['can_complete'] = true;
                self.form['skip_validation'] = true;
                $scope.$emit('formSubmit', self.form);
            } else {
                $('#reject_modal').modal({
                    keyboard: false
                })
            }
        });

        // Used for simple role send back
        $scope.$on('resetmodal', function (event, data) {
            $('#reject_modal').modal('hide');
            $('#sendbacktocorrection_modal').modal('hide');
            if (self.form.conclusion && self.form.conclusion[0] && self.form.conclusion[0].components && self.role.id) {
                var sendBackBtn = FormioUtils.getComponent(self.form.conclusion[0].components, "btn" + self.role.id + "filedecline");
                if (sendBackBtn) {
                    sendBackBtn.customClass = "formio-component-btnValidateEnabled btn-blue";
                    sendBackBtn.leftIcon = "";
                    delete data.sendbacktocorrectionstestsentback;
                }
            }
        });

        //TODO: clenup, probably not needed
        $scope.$on('submitmodal', function (event, data) {
            self.form['can_complete'] = true;
            self.form['skip_validation'] = true;
            $scope.$emit('formSubmit', self.form);
            $('#reject_modal').modal('hide');
            $('#sendbacktocorrection_modal').modal('hide');
        });

        $scope.$on('confirm', function (event, data) {
            self.form['can_complete'] = false;
            $scope.$emit('formSubmit', self.form);
        });

        $scope.$on('fileUploaded', function (event, fileName, fileInfo) {
            self.form['can_complete'] = false;
            fileInfo.document_id = fileInfo.name;
            if (fileInfo.url && !/\/backend\/documents\/\d+$/.exec(fileInfo.url)) {
                if (fileInfo.url.indexOf(fileInfo.name) === -1) {
                    fileInfo.url = fileInfo.url.replace(/\?.+/, '/') + fileInfo.name;
                }
            }
            self.setCertificateOrDocumentName(fileInfo);
            $scope.$emit('formSubmit', self.form);
        });

        $scope.$on('formComplete', function (event, data) {
            self.form['can_complete'] = true;
            self.form['skip_validation'] = false;
            $scope.$emit('formSubmit', self.form);
            $('#reject_modal').modal('hide');
            $('#sendbacktocorrection_modal').modal('hide');
        });

        $scope.$on('generatecertification', function (event, data) {
            console.log(event.targetScope.component);

            var component = event.targetScope.component;

            var post = {};
            console.log('Generate certificate');
            angular.forEach(component.properties, function (value, key) {
                console.log('Set field ' + key + ' to ' + self.form.data[key])
                post[value] = self.form.data[key];
            });
            console.log('The generate cert data', post);

            var url = component.botServiceUrl.replace('{processId}', self.file.id);

            Backend.generateCertificate(url, post).then(function (response) {
                console.log(response);
            })

        })

        this.formatDuration = Helpers.formatDuration;

        this.setCertificateOrDocumentName = function (doc) {
            var key = Helpers.getFileKey(self.form.data, doc);
            if (key != null) {
                if (key.match(/^fileupload.+/)) {
                    var title_key = key.replace("fileupload", "filetitle");
                    var cert_id_key = key.replace("fileupload", "certid");
                    var title = self.form.data[title_key];
                    var cert_id = self.form.data[cert_id_key];

                    Backend.setCertificateName(doc, title, cert_id)
                } else { // free file - not a cert, not specified for upload in partA
                    var docName;
                    self.loadedForms.some(function (formType) {
                        if (!self.form[formType] || !self.form[formType][0]) return;
                        var documentComponent = FormioUtils.getComponent(self.form[formType][0].components, key);
                        if (documentComponent && documentComponent.label) {
                            docName = $translate.instant(documentComponent.label);
                        }

                        return documentComponent;
                    });

                    Backend.setDocumentName(doc, docName || key);
                }

            }
        };

        /**
         * Carousel switcher on revision type role
         */
        $scope.$on('validatedocument', function (event) {
            var idx = 0;
            var file_parts = event.targetScope.componentId.split("fileupload");
            angular.forEach(self.form.B6, function (b6form) {
                if (b6form["name"].endsWith("B6" + file_parts[1])) {
                    $('#carouselRevision').carousel(idx);
                }
                idx += 1;
            });
        });

        this.printAnyForm = function (ev) {
            Helpers.printAnyForm({data: self.form.data, formId: ev.detail, service_id: self.service.service_id});
        };

        this.getStartofCurrentMonthForFinancialReportLink = function () {
            return moment().startOf('month').format().slice(0, 10);
        };

        // Investigate how not to have this nonsense (some binding issue)
        this.changeRoleToRestart = function (role) {
            self.roleToRestartAfter = role;
        }

        this.restartProcess = function (ev) {
            Backend.restartProcess(self.file.processDefinitionId, {
                "ended": self.file.ended,
                "instructions": [
                    {
                        "type": "startAfterActivity",
                        "activityId": self.roleToRestartAfter
                    }
                ],
                "processInstanceIds": [
                    self.file.id
                ],
                "initialVariables": false,
                "skipCustomListeners": false,
                "withoutBusinessKey": false
            }).then(function () {
                Notification.success({
                    message: $translate.instant('File successfully restarted!'),
                    replaceMessage: true
                });
                setTimeout(function () {
                    self.setUriParam('service', 'service_id', self.service.service_id, self.loaded_services, true);
                    self.chooseService();
                }, 3000);
            }).catch(function (err) {
                Notification.error({message: $translate.instant('Cannot restart file!'), replaceMessage: true});
            });
        };

        this.deleteProcess = function () {
            if (!self.file) return;
            var modal = document.querySelector('#confirmation-modal-2');
            if (!modal) return;
            modal.classList.add('show-modal');
            var modalConfirmation = document.querySelector('#confirmation-modal-confirm');
            var onRemove = function (ev) {
                ev.preventDefault();
                modalConfirmation.removeEventListener('click', onRemove);
                modal.classList.remove('show-modal');
                Backend.deleteProcess(self.file.id).then(function (res) {
                    if (res.status >= 200 && res.status < 300) {
                        self.setUriParam('service', 'service_id', self.service.service_id, self.loaded_services, true);
                        self.chooseService();
                    }
                }).catch(function (error) {
                    Notification.error({
                        message: $translate.instant('Some parts of deletion process were not successful. Please try again later or contact your administrator.'),
                        replaceMessage: true
                    });
                });
            }
            modalConfirmation.addEventListener("click", onRemove);
        }

        this.getServiceRoles = () => {
            return Backend.getServiceRoles(this.service.service_id, this.defaultRoleCountStatus).then(role_list => {
                this.service_role_list = role_list.filter((role) => {
                    // As per TOBE-7820, we don't want unused applicant role to be listed
                    return role.id !== 'applicant' || role.count > 0;
                });
                self.getServiceRolesCount();
                return this.service_role_list;
            });
        }

        this.getServiceRolesCount = function () {
            const isHideBots = localStorage.getItem("partBHideBOTRoles") === '1';
            this.showBotsInMenu = !isHideBots;
            let result = 0;
            if (!this.service_role_list) {
                result = 0;
            } else if (isHideBots) {
                result = this.service_role_list.filter((role) => role.type !== 'bot').length;
            } else {
                result = this.service_role_list.length;
            }
            this.visibleRolesCount = result;
        }

        addEventListener("showBotRolesChange", this.getServiceRolesCount.bind(this));

        GLOBALS.getConfirmationModalBody = function (result) {
            return $compile('<p class="confirmation-modal-text">Are you sure?</span>')($scope);
        }

        GLOBALS.confirmModal = function (result) {
            if (result) {
                self.restartProcess();
                return;
            }
        }

        this.emergencyGTPrintoutButton = function () {
            const e = new CustomEvent("PRINTOUT", {detail: "print2c92809577e34f4c0177e6879b5e0bd810813"});
            dispatchEvent(e);
        }
    }]);
