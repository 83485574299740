import './jquery.elevatezoom';
import Helpers from "./helpers";
import utils from './utils';

(function () {
    var module = angular.module('angular-elevate-zoom2', []);

    module.provider('ElevateZoomConfig', function () {
        this.zoomConfig = {};

        this.setZoomConfig = function setZoomConfig(zoomConfig) {
            this.zoomConfig = zoomConfig;
        };

        this.$get = function () {
            return this;
        };
    });

    module.directive('ezZoom', ['ezZoomSvc', function (ezZoomSvc) {
        return {
            restrict: 'AC',
            scope: {
                ezZoomConfig: "="
            },
            link: function ($scope, $elem, $attrs) {
                console.log(ezZoomSvc);
                $elem.on('mouseover', function(event) {
                    ezZoomSvc.remove(ezZoomSvc.CURRENT_ZOOM);
                    ezZoomSvc.attach($elem, $elem.attr("src"), $scope.ezZoomConfig);
                    ezZoomSvc.CURRENT_ZOOM = $elem;
                });
            }
        };
    }]);

    module.factory('ezZoomSvc', ['ElevateZoomConfig', function (ElevateZoomConfig) {
        return {
            attach: function (zoomImageDOM, dataZoomImage, zoomConfig) {
                var zoomImage = $(zoomImageDOM);

                zoomImage.data('zoom-image', dataZoomImage);
                zoomImage.elevateZoom(zoomConfig || ElevateZoomConfig.zoomConfig);
            },
            remove: function (zoomImageDOM) {
                $(".zoomContainer").remove();
            }
        }
    }]);
}());

var modules = ['formio', 'ui.mask', 'ngFileUpload', 'ui-notification', 'ngProgress',
    'angularUtils.directives.dirPagination', 'ui.bootstrap', 'angular-elevate-zoom2', 'pascalprecht.translate']
export var app = angular.module('app', modules);

app.factory('TranslationErrorHandler', ['$q', '$log', function ($q, $log) {
    return function (part, lang, response) {
        $log.info('The translation "' + part + '/' + lang + '" part was not loaded.');
        return $q.when({});
    };
}]).run(['$rootScope', '$translate', 'cfg', function ($rootScope, $translate, cfg) {
    $rootScope.cfg = cfg;
    $rootScope.$on('$translatePartialLoaderStructureChanged', function (data) {
        $translate.refresh();
    });
}]);

app.config(["$rootScopeProvider", function ($rootScopeProvider) {
  // Raise this from 10 because a big El Salvador service can trigger a form data update more times on initial load
  $rootScopeProvider.digestTtl(30);
}]);

// override formio table element
app.run([
    '$templateCache',
    '$interpolate',
    function($templateCache, $interpolate) {
      $templateCache.put('formio/components/table.html',
        "<div id=\"{{ component.key }}\">\n  <table ng-class=\"{'table-striped': component.striped, 'table-bordered': component.bordered, 'table-hover': component.hover, 'table-condensed': component.condensed}\" class=\"table\">\n    <thead ng-if=\"component.header.length\">\n      <th ng-repeat=\"header in component.header track by $index\">{{ header | formioTranslate:null:options.building }}</th>\n    </thead>\n    <tbody>\n      <tr ng-repeat=\"row in component.rows track by $index\" ng-init=\"rowscope={}; rowscope.allVisible=false\" ng-show=\"rowscope.allVisible\">\n        <td ng-repeat=\"column in row track by $index\" >\n          <formio-component\n  ng-init=\"rowscope.allVisible=rowscope.allVisible||isVisible(_component, data)\"          ng-repeat=\"_component in column.components track by $index\"\n            component=\"_component\"\n  form-name=\"formName\"          data=\"data\"\n            formio=\"formio\"\n            submission=\"submission\"\n            hide-components=\"hideComponents\"\n     ng-if=\"options.building ? '::true' : isVisible(_component, data)\"\n            formio-form=\"formioForm\"\n            read-only=\"isDisabled(_component, data)\"\n            grid-row=\"gridRow\"\n            grid-col=\"gridCol\"\n            options=\"options\"\n          ></formio-component>\n      </td>\n      </tr>\n    </tbody>\n  </table>\n</div>\n"
      );

      $templateCache.put('formio/componentsView/table.html',
        "<div id=\"{{ component.key }}\">\n  <table ng-class=\"{'table-striped': component.striped, 'table-bordered': component.bordered, 'table-hover': component.hover, 'table-condensed': component.condensed}\" class=\"table\">\n    <thead ng-if=\"component.header.length\">\n      <th ng-repeat=\"header in component.header track by $index\">{{ header }}</th>\n    </thead>\n    <tbody>\n      <tr ng-repeat=\"row in component.rows track by $index\" ng-init=\"rowscope={}; rowscope.allVisible=false\" ng-show=\"rowscope.allVisible\">\n        <td ng-repeat=\"column in row track by $index\">\n          <formio-component-view\n   ng-init=\"rowscope.allVisible=rowscope.allVisible||isVisible(_component, data)\"          ng-repeat=\"_component in column.components track by $index\"\n            component=\"_component\"\n            data=\"data\"\n  form-name=\"formName\"    form=\"form\"\n            submission=\"submission\"\n            ignore=\"ignore\"\n            ng-if=\"options.building ? '::true' : isVisible(_component, data)\"\n            options=\"options\"\n          ></formio-component-view>\n        </td>\n      </tr>\n    </tbody>\n  </table>\n</div>\n"
      );
    }
]);

//override formio formio-image-list element
app.run([
    '$templateCache',
    '$interpolate',
    function($templateCache, $interpolate) {
        $templateCache.put('formio/components/formio-image-list.html',
        "<div>\n  <span ng-repeat=\"file in files track by $index\" ng-if=\"file\">\n    <div style=\"display: 'block';margin: 10px 0;\"><formio-image file=\"file\" form=\"form\" width=\"width\"></formio-image>\n    <span ng-if=\"!readOnly\" style=\"width:1%;white-space:nowrap;\">\n      <a href=\"#\" ng-click=\"removeFile($event, $index)\" style=\"padding: 2px 4px;\" class=\"btn btn-sm btn-default\"><span class=\"glyphicon glyphicon-remove\"></span></a>\n    </span>\n </div>\n </span>\n</div>\n"
      );
    }
]);

app.directive('formioImageList',
    function () {
        return {
            scope: false,
            require: 'formioImageList',
            link: function (scope, element, attr) {
                var innerScope = angular.element(element[0]).isolateScope();
                var component = innerScope.$parent.component;
                innerScope.removeFile = function ($event, $index) {
                    var fileToRemove = innerScope.files[$index];
                    $event.preventDefault();
                    if (component.storage === 'url') {
                        var url = utils.replaceWithEvaluate(component.url, innerScope.$parent.data);
                        innerScope.$parent.formio.makeRequest('', url + '/' + fileToRemove.document_id, 'delete').finally(function () {
                            innerScope.files.splice($index, 1);
                            dispatchEvent(new CustomEvent("updateDocuments"));
                        });
                    }
                }
            }
        }
    }
);

// override formio errors.html to add custom translation logic
app.run([
    '$templateCache',
    '$interpolate',
    function($templateCache, $interpolate) {
        $templateCache.put('formio/errors.html',
        "<div ng-show=\"formioForm[componentId].$error && !formioForm[componentId].$pristine\">\n" +
            "  <div ng-show=\"component.validate.customMessage && (\n" +
            "    formioForm[componentId].$error.email ||\n" +
            "    formioForm[componentId].$error.required ||\n" +
            "    formioForm[componentId].$error.number ||\n" +
            "    formioForm[componentId].$error.maxlength ||\n" +
            "    formioForm[componentId].$error.minlength ||\n" +
            "    formioForm[componentId].$error.min ||\n" +
            "    formioForm[componentId].$error.max ||\n" +
            "    formioForm[componentId].$error.custom ||\n" +
            "    formioForm[componentId].$error.pattern ||\n" +
            "    formioForm[componentId].$error.day\n" +
            "  )\">\n" +
            "      <p class=\"help-block\">{{ component.validate.customMessage | translate:{ placeholder: data[component.key + '-customMessage-placeholder'], placeholder1: data[component.key + '-customMessage-placeholder1'], placeholder2: data[component.key + '-customMessage-placeholder2'] } }}</p>\n" +
            "  </div>\n" +
            "  <div ng-hide=\"component.validate.customMessage\">\n" +
            "    <p class=\"help-block\" ng-show=\"formioForm[componentId].$error.email\">{{ 'component must be a valid email' | translate:{ component: component.errorLabel || component.label || component.placeholder || component.key } }}</p>\n" +
            "    <p class=\"help-block\" ng-show=\"formioForm[componentId].$error.required\">{{ 'component is required' | translate:{ component: component.errorLabel || component.label || component.placeholder || component.key } }}</p>\n" +
            "    <p class=\"help-block\" ng-show=\"formioForm[componentId].$error.number\">{{ 'component must be a number' | translate:{ component: component.errorLabel || component.label || component.placeholder || component.key } }}</p>\n" +
            "    <p class=\"help-block\" ng-show=\"formioForm[componentId].$error.maxlength\">{{ 'component must be shorter than maxLength characters' | translate: { component: component.errorLabel || component.label || component.placeholder || component.key, maxLengthPlusOne: component.validate.maxLength + 1 } }}></p>\n" +
            "    <p class=\"help-block\" ng-show=\"formioForm[componentId].$error.minlength\">{{ 'component must be longer than minLengthMinusOne characters' | translate: { component: component.errorLabel || component.label || component.placeholder || component.key, minLengthMinusOne: component.validate.minLength - 1 } }}</p>\n" +
            "    <p class=\"help-block\" ng-show=\"formioForm[componentId].$error.min\">{{ 'component must be greater than or equal to min' | translate: { component: component.errorLabel || component.label || component.placeholder || component.key, min: component.validate.min } }}</p>\n" +
            "    <p class=\"help-block\" ng-show=\"formioForm[componentId].$error.max\">{{ 'component must be less than or equal to max' | translate: { component: component.errorLabel || component.label || component.placeholder || component.key, max: component.validate.max} }}</p>\n" +
            "    <p class=\"help-block\" ng-show=\"formioForm[componentId].$error.custom\">{{ component.customError | formioTranslate }}</p>\n" +
            "    <p class=\"help-block\" ng-show=\"formioForm[componentId].$error.pattern\">{{ 'component does not match the pattern validatePattern' | translate: { component: component.errorLabel || component.label || component.placeholder || component.key, validatePattern: component.validate.pattern } }} </p>\n" +
            "    <p class=\"help-block\" ng-show=\"formioForm[componentId].$error.day\">{{ 'component must be a valid date' | translate: { component: component.errorLabel || component.label || component.placeholder || component.key } }}</p>\n" +
            "  </div>\n" +
            "</div>\n"
      );
    }
]);

// override formio checkbox.html to add custom logic ng-if="options.building ? '::true' : isVisible(_component, data)"
app.run([
    '$templateCache',
    '$interpolate',
    function($templateCache, $interpolate) {
        $templateCache.put('formio/components/checkbox.html',
        '<div class="checkbox">'+
            '<label for="{{ componentId }}" ng-class="{\'field-required\': isRequired(component)}" ng-style="getOptionLabelStyles(component)">'+
                '<span ng-if="(options.building || !component.hideLabel) && topOrLeftOptionLabel(component)">'+
                    '{{ component.label | formioTranslate:null:options.building }}'+
                    '<formio-component-tooltip></formio-component-tooltip>'+
                '</span>'+
                '<input'+
                    ' ng-if="component.name"'+
                    ' type="{{ component.inputType }}"'+
                    ' id="{{ componentId }}"'+
                    ' name="{{ component.name }}"'+
                    ' value="{{ component.value }}"'+
                    ' tabindex="{{ component.tabindex || 0 }}"'+
                    ' ng-disabled="readOnly"'+
                    ' ng-model="data[component.name]"'+
                    ' ng-required="component.validate.required"'+
                    ' ng-style="getOptionInputStyles(component)">'+
                '<input'+
                    ' ng-if="!component.name"'+
                    ' type="{{ component.inputType }}"'+
                    ' id="{{ componentId }}"'+
                    ' name="{{ componentId }}"'+
                    ' tabindex="{{ component.tabindex || 0 }}"'+
                    ' ng-disabled="readOnly"'+
                    ' ng-model="data[component.key]"'+
                    ' ng-required="isRequired(component)"'+
                    ' custom-validator="component.validate.custom"'+
                    ' ng-style="getOptionInputStyles(component)">'+
                '<span ng-if="(options.building || !component.hideLabel) && !topOrLeftOptionLabel(component)">'+
                    '{{ component.label | formioTranslate:null:options.building | shortcut:component.shortcut }}'+
                    '<formio-component-tooltip></formio-component-tooltip>'+
                '</span>'+
            '</label>'+
        '</div>'+
        '<div ng-if="!!component.description" class="help-block">'+
            '<span>{{ component.description }}</span>'+
        '</div>'
      );
    }
]);

app.config([
    'formioComponentsProvider',
    function(formioComponentsProvider) {
      formioComponentsProvider.register('mycontent', {
        title: 'My Content',
        template: 'formio/components/mycontent.html',
        group: 'advanced',
        settings: {},
      });

      formioComponentsProvider.register('complexdatagrid', {
        title: 'Complex datagrid',
        template: 'formio/components/complexdatagrid.html',
        group: 'advanced',
        settings: {},
      });

      formioComponentsProvider.register('mypanel', {
        title: 'My Panel',
        template: 'formio/components/mypanel.html',
        group: 'advanced',
        settings: {},
      });

      formioComponentsProvider.register('myswitch', {
        title: 'My Switch',
        template: 'formio/components/myswitch.html',
        group: 'advanced',
        settings: {},
      });

      formioComponentsProvider.register('mycounter', {
        title: 'My Counter',
        template: 'formio/components/mycounter.html',
        group: 'advanced',
        settings: {},
      });

      formioComponentsProvider.register('mytabs', {
        title: 'My Tabs',
        template: 'formio/components/mytabs.html',
        group: 'advanced',
        settings: {},
      });

      formioComponentsProvider.register('mycard', {
        title: 'My Tabs',
        template: 'formio/components/mycard.html',
        group: 'advanced',
        settings: {},
      });

      formioComponentsProvider.register('mycardtable', {
        title: 'My Tabs',
        template: 'formio/components/mycardtable.html',
        group: 'advanced',
        settings: {},
      });

      formioComponentsProvider.register('mytooltip', {
        title: 'My Tooltip',
        template: 'formio/components/mytooltip.html',
        group: 'advanced',
        settings: {},
      });
      formioComponentsProvider.register('myecoactivity', {
        title: 'My Remote Iframe Resource',
        template: 'formio/components/myecoactivity.html',
        group: 'advanced',
        settings: {},
      });
      formioComponentsProvider.register('cubanproductlist', {
        title: 'Prodcut List',
        template: 'formio/components/cubanproductlist.html',
        group: 'advanced',
        settings: {},
      });
      formioComponentsProvider.register('cubanproductlistview', {
        title: 'Prodcut List View',
        template: 'formio/components/cubanproductlistview.html',
        group: 'advanced',
        settings: {},
      });
      formioComponentsProvider.register('qrcode', {
        title: 'QR code',
          template: 'formio/components/qrcode.html',
          group: 'advanced',
          settings: {},
      });
        formioComponentsProvider.register('barcode', {
            title: 'Bar Code',
            template: 'formio/components/barcode.html',
            group: 'advanced',
            settings: {},
        });
    }
]);

app.run([
    '$templateCache',
    '$interpolate',
    function($templateCache, $interpolate) {
      $templateCache.put('formio/components/mycontent.html',
        "<mycontent component='component' data='data' submission='submission' form='form' ignore='ignore' builder='builder'></mycontent>"
      );

      $templateCache.put('formio/components/complexdatagrid.html',
      "<formio-complex-datagrid component='component' data='data' submission='submission' formio='formio' formio-form='formioForm' ignore='ignore' builder='builder' options='options'></formio-complex-datagrid>");

      $templateCache.put('complexdatagrid-component.html', '<div class="formio-data-grid"> '+
        '<div class="panel panel-{{ component.theme }}" id="{{ component.key }}">'+
        '<div ng-if="component.title" class="panel-heading">'+
        '<h3 class="panel-title">{{ component.title }}</h3>'+
        '</div>'+
        '<div class="panel-body">'+
        '<div ng-show="editMode"> '+
        '<div ng-repeat="_component in component.components track by $index">' +
              '<formio-component '+
                  'component="_component" '+
                  'data="tmpData" '+
                  'submission="tmpSubmission" '+
                  'ng-if="builder ? \'::true\' : isVisible(_component, tmpData)" '+
                  'form="form" '+
                  'formio-form="formioForm" '+
                  'formio="formio" '+
                  'grid-row="gridRow" '+
                  'grid-col="gridCol" '+
                  'options="options" ' +
                  'builder="builder" '+ '>' +
              '</formio-component>' +
              '</div>' +
              '<button class="btn ink-reaction btn-default btn-sm ng-scope" type="reset" name="yt11" ng-click="cancelEditMode()" translate>Reset</button> ' +
              '<button id="btn_declaration_honneur" class="text-normal  btn ink-reaction btn-sm btn-primary" ng-click="saveEditMode()" name="yt12" type="button">'+
              '<i class="md md-check"></i> Save</button>'+
        '</div>'+
      '<table ng-class="{\'table-striped\': component.striped, \'table-bordered\': component.bordered, \'table-hover\': component.hover, \'table-condensed\': component.condensed}" class="table datagrid-table">'+
        '<tr>'+
          '<th '+
            'ng-repeat="col in cols track by $index" ng-class="{\'field-required\': col.validate.required}" ng-if="builder ? \'::true\' : anyVisible(col)">' +
            '{{ col.label | formioTranslate:null:builder }}' +
          '</th>'+
        '</tr>'+
        '<tr ng-repeat="row in rows track by $index" ng-init="rowIndex = $index">'+
          '<td ng-repeat="col in cols track by $index" ng-init="col.hideLabel = true; colIndex = $index" class="formio-data-grid-row" ng-if="builder ? \'::true\' : anyVisible(col)">'+
            '<formio-component '+
              'component="col" '+
              'data="rows[rowIndex]" '+
              'form="form" '+
              'formio-form="formioForm" '+
              'formio="formio" '+
              'submission="{data:rows[rowIndex]}" '+
              'hide-components="hideComponents" '+
              'ng-if="!col.hideFromGrid" '+
              'read-only="true" '+
              'grid-row="rowIndex" '+
              'grid-col="colIndex" '+
              'builder="builder" '+
            '></formio-component>'+
          '</td>'+
          '<td ng-if="!component.hasOwnProperty(\'validate\') || !component.validate.hasOwnProperty(\'minLength\') || rows.length > component.validate.minLength">'+
            '<a ng-click="editRow(rowIndex)" class="btn btn-default">'+
              '<span class="glyphicon glyphicon-pencil"></span>'+
            '</a> &nbsp;'+
            '<a ng-click="removeRow(rowIndex)" class="btn btn-default">'+
              '<span class="glyphicon glyphicon-remove-circle"></span>'+
            '</a>'+
          '</td>'+
        '</tr>'+
      '</table>'+
      '<div class="datagrid-add" ng-if="!component.hasOwnProperty(\'validate\') || !component.validate.hasOwnProperty(\'maxLength\') || rows.length < component.validate.maxLength">'+
        '<a ng-click="addRow()" class="btn btn-primary">'+
          '<span class="glyphicon glyphicon-plus" aria-hidden="true"></span> {{ component.addAnother || "Add Another" | formioTranslate:null:builder }}'+
        '</a>'+
      '</div>'+
    '</div>' +
'  </div> </div>');
    }
]);

app.directive('formioComplexDatagrid', ['$templateCache',
    function($templateCache) { function ComplexDatagridController($scope, FormioUtils) {
        $scope.editMode = false;
        $scope.tmpData = {};
        $scope.tmpSubmission = {};

        if ($scope.builder) return;
          // Ensure each data grid has a valid data model.
        $scope.data = $scope.data || {};
        $scope.data[$scope.component.key] = $scope.data[$scope.component.key] || [];

        // Determine if any component is visible.
        $scope.anyVisible = function(component) {
            var data = $scope.data[$scope.component.key];
            var visible = false;
            angular.forEach(data, function(rowData) {
                visible = (visible || FormioUtils.isVisible(component, rowData, $scope.data, $scope.hideComponents));
            });
            return visible;
        };

        $scope.isVisible = function(component, rowData) {
            var visible = FormioUtils.isVisible(component, rowData, $scope.data, $scope.hideComponents);
            return visible;
        };

        function touchComponents(rootComponent) {
            rootComponent.clearOnHide = false;
            rootComponent.clearOnRefresh = false;
            if (rootComponent.components) {
                angular.forEach(rootComponent.components, touchComponents);
            }
        }

        // Pull out the rows and cols for easy iteration.
        $scope.rows = $scope.data[$scope.component.key];
        $scope.cols = angular.copy($scope.component.components);
        angular.forEach($scope.cols, function(_comp) {
            touchComponents(_comp)
        });

        // If less than minLength, add that many rows.
        if ($scope.component.validate && $scope.component.validate.hasOwnProperty('minLength') && $scope.rows.length < $scope.component.validate.minLength) {
            var toAdd = $scope.component.validate.minLength - $scope.rows.length;
            for (var i = 0; i < toAdd; i++) {
                $scope.rows.push({});
            }
        }
          // If more than maxLength, remove extra rows.
        if ($scope.component.validate && $scope.component.validate.hasOwnProperty('maxLength') && $scope.rows.length < $scope.component.validate.maxLength) {
            $scope.rows = $scope.rows.slice(0, $scope.component.validate.maxLength);
        }

        touchComponents($scope.component);
        $scope.localKeys = $scope.component.components.map(function(_comp) {
            return _comp.key;
        });

        $scope.editRow = function(rowIndex) {
            $scope.editMode = true;
            $scope.selectedRowIndex = rowIndex;
            $scope.tmpData = angular.copy($scope.rows[rowIndex]);
            $scope.tmpSubmission['data'] = $scope.tmpData;
        };

        $scope.addRow = function() {
            $scope.editMode = true;
            $scope.selectedRowIndex = -1;
            $scope.tmpData = {};
            angular.forEach($scope.component.components, function(component) {
                if ($scope.formioForm[component.key]) {
                    $scope.formioForm[component.key].$pristine = true;
                }
            });
            $scope.tmpSubmission['data'] = $scope.tmpData;
        };

        $scope.dump = function() {
            console.log($scope.data);
            console.log($scope.component);
            console.log($scope.tmpData);
        };

        $scope.saveEditMode = function() {
            var $allValid = true;
            angular.forEach($scope.component.components, function(component) {
                if ($scope.formioForm[component.key]) {
                    console.log($scope.formioForm[component.key]);
                    if (!$scope.formioForm[component.key].$valid) {
                        $allValid = false;
                    }
                    $scope.formioForm[component.key].$pristine = false;
                }
            });
            if(!$allValid) {
                return;
            }

            if ($scope.selectedRowIndex >= 0) {
                angular.copy($scope.tmpData, $scope.rows[$scope.selectedRowIndex]);
            } else {
                $scope.rows.push($scope.tmpData);
            }
            $scope.selectedRowIndex = -1;
            $scope.editMode = false;
        };

        $scope.cancelEditMode = function() {
            $scope.selectedRowIndex = -1;
            $scope.editMode = false;
        };

          // Remove a row from the grid.
        $scope.removeRow = function(index) {
            $scope.rows.splice(index, 1);
        };
    };

    return {
        scope: {
            component: '=',
            data: '=',
            formioForm: '=',
            formio: '=',
            submission: '=',
            hideComponents: '=',
            readOnly: '=',
            gridRow: '=',
            gridCol: '=',
            builder: '=',
            options: '=',
        },
        controller: ['$scope', 'FormioUtils', ComplexDatagridController],
        templateUrl: 'complexdatagrid-component.html',
    };
}]);

app.directive('mycontent', ['$parse', '$compile', '$translate',
    function($parse, $compile, $translate, $sce) {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            component:'=',
            data:'='
        },
        link: function(scope, element, attrs, controller) {
            var component = $parse(attrs.component)(scope);
            $translate(component.content, scope.data).then(function (translation_text) {
                /*jquery html falls apart if ending tag has whitespaces in and ruins the html */
                element.html(translation_text.replace(/<\/[\s]*/g, "</"));
                $compile(element.contents())(scope);
            }, function (translation_id) {
                translation_id = translation_id || "";
                element.html(translation_id.replace(/<\/[\s]*/g, "</"));
                $compile(element.contents())(scope);
            })
        }
    };
}]);


/**
 * mypanel formio component
 */
app.run([
    '$templateCache',
    '$interpolate',
    function($templateCache, $interpolate) {
        $templateCache.put('formio/components/mypanel.html',
            '<div class="panel panel-{{ component.theme }}" id="{{ component.key }}">'+
  '<div ng-if="component.title" class="panel-heading">'+
    '<h3 class="panel-title">{{ component.title | formioTranslate:null:builder }}</h3>'+
  '</div>'+
  '<div class="panel-body">'+
    '<div ng-if="component.subtitle" class="alert alert-info"><p>{{ component.subtitle | formioTranslate:null:builder }}</p></div>' +
    '<formio-component '+
      'ng-repeat="_component in component.components track by $index" '+
      'component="_component" '+
      'data="data" '+
      'formio="formio" '+
      'submission="submission" '+
      'hide-components="hideComponents" '+
      'ng-if="builder ? \'::true\' : isVisible(_component, data)" '+
      'read-only="isDisabled(_component, data)" '+
      'form="form" '+
      'formio-form="formioForm" '+
      'grid-row="gridRow" '+
      'grid-col="gridCol" '+
      'builder="builder" '+
      'options="options"' +
      '</formio-component>'+
  '</div>' +
  '<div class="style-default-light " ng-if="component.footerText">'+
    '<blockquote class="no-margin"><small>{{ component.footerText | formioTranslate:null:builder }}</small></blockquote>'+
  '</div></div>');
    }
]);

/**
 * myswitch formio component
 */
app.run([
    '$templateCache',
    '$interpolate',
    function($templateCache, $interpolate) {
        $templateCache.put('formio/components/myswitch.html',
            '<switch-box component="component" data="data" formio-form="formioForm"></switch-box>'
        );
    }
]);

app.directive('switchBox', ['$parse', '$compile',
    function($parse, $compile) {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            component:'=',
            data:'=',
            formioForm:'=',
        },
        template: '<div class="form-group"><label>{{ component.label | formioTranslate:null:builder }}</label>'+
                '<div class="switch-box">'+
                    '<span class="switch-button-label" ng-class="{off: checked===true, on: checked===false}" ng-click="setSwitch(false)">'+
                        '<span class="no">{{ component.falseText | formioTranslate:null:builder }}</span>'+
                    '</span>'+
                    '<div class="switch-button-background" ng-class="{checked: checked}" style="width: 102px; height: 30px;" ng-click="toggleSwitch()">'+
                        '<span class="switch-button-button" style="width: 52px; height: 30px;"></span>'+
                    '</div>'+
                    '<span class="switch-button-label" ng-class="{off: checked===false, on: checked===true}" ng-click="setSwitch(true)">'+
                        '<span class="yes">{{ component.trueText | formioTranslate:null:builder }}</span>'+
                    '</span>'+
                    '<div style="clear: left;"></div>'+
                '</div>'+
            '</div>',
        controller: ['$scope', function($scope) {
            if (!$scope.component.trueText)
                $scope.component.trueText = 'True';
            if (!$scope.component.falseText)
                $scope.component.falseText = 'False';

            $scope.toggleSwitch = function () {
                if ($scope.checked) {
                    $scope.checked = false;
                } else {
                    $scope.checked = true;
                }
                $scope.updateData();
            };

            $scope.setSwitch = function(state) {
                $scope.checked = state;
                $scope.updateData();
            };

            $scope.updateData = function() {
                var value;
                if ($scope.checked) {
                    value = $scope.component.trueValue;
                } else {
                    value = $scope.component.falseValue;
                }
                $scope.data[$scope.component.key] = value;
            };

            // check for default value
            if ($scope.data && $scope.component && $scope.component.key && $scope.component.key in $scope.data) {
                // this means we have a default value
                if ($scope.data[$scope.component.key]) {
                    $scope.checked = true;
                } else {
                    $scope.checked = false;
                }
                $scope.updateData();
            }
        }]
    };
}]);

/**
 * mycounter formio component
 */

app.run([
    '$templateCache',
    '$interpolate',
    function($templateCache, $interpolate) {
        $templateCache.put('formio/components/mycounter.html',
            '<mycounter component="component" data="data"></mycounter>');
    }
]);


app.directive('mycounter', function() {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            component:'=',
            data:'=',
        },
        template: '<span class="bullet style-gray">{{counterIndex}}</span>',
        controller: ['$scope', '$rootScope', function($scope, $rootScope) {
            var startIndex = $scope.component.startIndex;
            if(!startIndex && startIndex !== 0) {
                startIndex = 1;
            }
            var item_name = $scope.component.key;
            var allGroups = $rootScope.COUNTER_GROUPS;
            if (!allGroups) {
                allGroups = {};
                $rootScope.COUNTER_GROUPS = allGroups;
            }

            if (!item_name) {
                item_name = "idx_"+Object.keys(allGroups).length;
            }

            var value = allGroups[item_name];
            if (!value) {
                value = startIndex + Object.keys(allGroups).length;
                allGroups[item_name] = value;
            }
            $scope.counterIndex = value;
        }]
    };
});

/**
 * mytabs formio component
 */

app.run([
    '$templateCache',
    '$interpolate',
    function($templateCache, $interpolate) {
        $templateCache.put('formio/components/mytabs.html',
            '<div class="tabs-above">' +
                '<ul class="nav nav-tabs" role="tablist">' +
                    '<li ng-repeat="_component in component.components" ng-class="{active: $first}" ng-if="options.building ? \'::true\' : isVisible(_component, data)"><a data-toggle="tab" href="#{{_component.key}}" aria-expanded="true">{{_component.title | formioTranslate:null:builder }}</a></li>' +
                '</ul>' +
                '<div class="tab-content">' +
                    '<div id="{{_component.key}}" class="tab-pane fade" ng-class="{active: $first, in: $first}" role="tabpanel" ng-repeat="_component in component.components">' +
                        '<div class="card style-default no-margin no-radius">'+
                            '<div class="card-body">'+
                                '<formio-component ng-if="options.building ? \'::true\' : isVisible(_comp, data)" component="_comp" data="data" ng-repeat="_comp in _component.components" submission="submission" '+
                                    'form="form" '+
                                    'formio="formio" '+
                                    'formio-form="formioForm" '+
                                    'options="options" ' +
                                    'builder="builder">' +
                                '</formio-component>'+
                            '</div>'+
                        '</div>' +
                    '</div>' +
                '</div>' +
            '</div>');
    }
]);

/**
 * mycard formio component
 */
app.run([
    '$templateCache',
    '$interpolate',
    function($templateCache, $interpolate) {
        $templateCache.put('formio/components/mycard.html',
            '<div class="card" ng-class="{\'card-underline card-outlined style-primary\': !component.smallCard, \'style-default-light\': component.smallCard}">'+
                '<div class="card-head" ng-class="{\'card-head-xs text-medium\': component.smallCard}" ng-if="component.title">'+
                    '<header ng-if="!component.smallCard"><h3 translate>{{component.title}}</h3></header>'+
                    '<blockquote class="small-padding text-primary" ng-if="component.smallCard" translate>{{component.title}}</blockquote>'+
                '</div>'+
                '<div class="card-body" ng-class="{\'no-padding\': !component.smallCard, \'style-default-bright\': component.smallCard}" >'+
                    '<formio-component component="_comp" data="data" ng-repeat="_comp in component.components" submission="submission" '+
                        'form="form" '+
                        'formio="formio" '+
                        'formio-form="formioForm" '+
                        'options="options" ' +
                        'builder="builder" ' +
                        'read-only="isDisabled(_comp, submission.data)" '+
                        'ng-if="isVisible(_comp, data)"></formio-component>'+
                '</div>'+
            '</div>');
    }
]);

/**
 * mycardtable formio component
 *
 * type: mycardtable
 * header (optional): array of header elements. if array item is array, then second item is used as key for sorting
 *
 */
app.run([
    '$templateCache',
    '$interpolate',
    function($templateCache, $interpolate) {
        $templateCache.put('formio/components/mycardtable.html',
            '<mycardtable component="component" data="data"></mycardtable>');
    }
]);

app.directive('mycardtable', ['$parse', '$compile', 'FormioUtils', '$translate', '$sce',
    function($parse, $compile, FormioUtils, $translate, $sce) {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            component:'=',
            data:'=',
            submission: '=',
        },
        template: '<table class="no-margin table table-striped table-bordered hbox-xs" ng-class="{\'th-width\': !component.tableRows}">' +
            '<thead ng-if="component.header && hasContent(component, data)">' +
                '<tr><th ng-repeat="header in component.header track by $index" ng-show="isVisible(header, null, data)">' +
                    '<a href="#{{header[1]}}" ng-if="isArray(header)">{{header[0]}}</a>' +
                    '<span ng-if="!isArray(header)">{{header}}</span>' +
                '</th></tr>' +
            '</thead>' +
            '<thead ng-if="component.headerComponent && hasContent(component, data)">' +
                '<tr><th ng-repeat="header in component.headerComponent track by $index" ng-show="isVisible(header, null, data)">' +
                    '<span ng-if="header.shortLabel">{{header.shortLabel}}</span>' +
                    '<span ng-if="!header.shortLabel">{{header.label}}</span>' +
            '</th></tr>' +
            '</thead>' +
            '<tbody>' +
                '<tr ng-if="!component.tableRows || !component.tableRows.length" ng-class-odd="odd" ng-class-even="even" ng-repeat="_comp in component.components" ng-show="isVisible(_comp, null, data)">' +
                    '<th ng-if="!isRegistration(component)" ng-bind-html="_comp._visibleLabel | formioTranslate:null:builder"></th>' +
                    '<th ng-if="isRegistration(component)" colspan="2">{{_comp._visibleLabel | formioTranslate:null:builder}}</th>' +
                    '<td ng-if="_comp.type===\'select\' && !isRegistration(component) && !data[_comp.key + \'Label\']"><turtle-select id="turtle_select_{{_comp.key}}" component="_comp" data="data" submission="submission"></turtle-select></td>' +
                    '<td ng-if="(_comp.type!==\'select\' || data[_comp.key + \'Label\']) && !isRegistration(component)" ng-bind-html="getVisibleValue(_comp, data)"></td>' +
                '</tr>'+
                '<tr ng-if="component.tableRows && component.tableRows.length" ng-class-odd="odd" ng-class-even="even" ng-repeat="_data_row in data[component.key]">' +
                    '<td ng-repeat="_comp in component.tableRows" ng-if="!_comp.hidden" ng-show="isVisible(_comp, _data_row)"><turtle-select id="turtle_select_{{_comp.key}}" ng-if="_comp.type===\'select\' && !data[_comp.key + \'Label\']" component="_comp" data="_data_row" submission="submission"></turtle-select>' +
                    '<span ng-if="_comp.type!==\'select\' || data[_comp.key + \'Label\']">{{ getVisibleValue(_comp, _data_row) }}</span></td>' +
                '</tr>'+
        '</tbody></table>',
        controller: ['$scope', 'FormioUtils', function($scope, FormioUtils) {
            $scope.isArray = angular.isArray;

            $scope.hasContent = function(component, data) {
                if (!component) return;
                if(component.hidden) {
                    return false;
                }
                var val;
                if (data[component.key] ) {
                    val = data[component.key].toString();
                } else {
                    return false;
                }

                return !!val;
            };

            $scope.isVisible = function(component, row, data) {

                if (!component) {
                    return;
                }
                if(component.hidden) {
                    return false;
                }
                return FormioUtils.isVisible(component, row || {}, data || {}, $scope.ignore);
            };

            $scope.getVisibleValue = function(component, data) {
                return GLOBALS.getComponentDisplayValue(component, data, null, $scope);
            };

            $scope.isRegistration = function (comp) {
                return Boolean(comp.key.match(/^registrations.+/));
            }

            // go over all components and make sure everyone has '_visibleValue' attribute set
            if($scope.component.components) {
                angular.forEach($scope.component.components, function (comp) {
                    comp._visibleValue = $scope.data[comp.key];
                    comp._displayedValue = $scope.getVisibleValue(comp, $scope.data); 
                    if ("label" in comp) {
                        comp._visibleLabel = comp.label;
                    } else if ( "html" in comp ) {
                        comp._visibleLabel = comp.html;
                    } else if ( "title" in comp ) {
                        comp._visibleLabel = comp.title;
                    }
                });
            }
            if($scope.component.rows) {
                angular.forEach($scope.component.rows, function (row) {
                    angular.forEach(row, function(comp) {
                        comp._visibleValue = $scope.data[comp.key];
                        if ("label" in comp) {
                            comp._visibleLabel = comp.label;
                        } else if ( "html" in comp ) {
                            comp._visibleLabel = comp.html;
                        } else if ( "title" in comp ) {
                            comp._visibleLabel = comp.title;
                        }
                    })
                });
            }
        }],
    };
}]);

/**
 * modify Formio directives
 */
app.config(['$provide', function ($provide) {
    $provide.decorator('editGridRowDirective', ['$delegate', function ($delegate) {
        var directive = $delegate[0];
        directive.template = '' +
        '<div ng-if="openRows.indexOf(rowIndex) !== -1">' +
        '  <div class="edit-body {{component.rowClass}}">' +
        '    <div class="editgrid-edit">' +
        '      <div class="editgrid-body">' +
        '        <ng-form name="formioForm">' +
        '          <formio-component' +
        '            ng-repeat="col in component.components track by $index"' +
        '            ng-init="colIndex = $index"' +
        '            component="col"' +
        '            data="rowData"' +
        '            formio="formio"' +
        '            submission="submission"' +
        '            hide-components="hideComponents"' +
        '            ng-if="options.building ? \'::true\' : isVisible(col, rowData)"' +
        '            form-name="formName"' +
        '            read-only="isDisabled(col, rowData)"' +
        '            grid-row="rowIndex"' +
        '            grid-col="colIndex"' +
        '            options="options"' +
        '          />' +
        '        </ng-form>' +
        '        <div class="editgrid-actions">' +
        '          <div ng-click="editDone(formioForm)" class="btn btn-primary"><span translate="saveRow">{{ component.saveRow || \'Save\' }}</span></div>' +
        '          <div ng-if="component.removeRow" ng-click="removeRow(rowIndex)" class="btn btn-danger"><span translate="removeRow">{{component.removeRow || \'Cancel\' }}</span></div>' +
        '        </div> ' +
        '      </div>' +
        '    </div>' +
        '  </div>' +
        '</div>' +
        '<div ng-if="openRows.indexOf(rowIndex) === -1">' +
        '  <render-template template="component.templates.row" data="templateData" actions="actions"/>' +
        '</div>';

        return $delegate;
    }]);

    $provide.decorator('formioImageDirective', ['$delegate', function ($delegate) {
        var directive = $delegate[0];
        directive.template = '<span ng-switch="{{ type }}">' +
            '<a ng-switch-when="1" class="fancy img-thumbnail size-4 border-gray border-lg" href="{{ file.imageSrc }}" target="_blank"><i class="fa fa-file-pdf fa-3x"></i></a>' +
            '<a ng-switch-when="2" class="fancy img-thumbnail size-4 border-gray border-lg" href="{{ file.imageSrc }}" target="_blank"><i class="fa fa-file-word fa-3x"></i></a>' +
            '<img ng-switch-default ng-src="{{ file.imageSrc }}" alt="{{ file.name }}" style="height: 160px" />' +
            '</span>';
        directive.controller = ['$rootScope', '$scope', 'Formio', function($rootScope, $scope, Formio) {
            if ($scope.builder) return;
            /*ng-switch not working with string for some reason*/
            if ($scope.file.type == 'application/pdf') { $scope.type = 1; }
            if ($scope.file.type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') { $scope.type = 2; }
            $scope.form = $scope.form || $rootScope.filePath;
            $scope.options = $scope.options || {};
            var baseUrl = $scope.options.baseUrl || Formio.getBaseUrl();
            var formio = new Formio($scope.form, {base: baseUrl});
            formio.downloadFile($scope.file).then(function (result) {
                var url = result.url;
                var fName = $scope.file && $scope.file.name;
                //sometimes downloadFile returns empty object... to be investigated why
                if (url && typeof url === 'string') {
                    if (fName && typeof fName === 'string' && url.match(fName)) {
                            url = url.replace(new RegExp("(" + fName + ")+"), fName); // sometimes duplicated name is at the end of url comming from download file
                        }
                    $scope.file.imageSrc = url;
                }
                $scope.$apply();
            });
        }];
        return $delegate;
    }]);
}]);

/**
 * override button template
 */
app.run([
    '$templateCache',
    '$interpolate',
    function($templateCache, $interpolate) {
        $templateCache.put('formio/components/button.html',
            '<button ng-attr-type="{{ getButtonType() }}"\n' +
            '    id="{{ componentId }}"\n' +
            '    name="{{ componentId }}"\n' +
            '    ng-class="{\'btn-block\': component.block}"\n' +
            '    class="btn btn-{{ component.theme }} btn-{{ component.size }}"\n' +
            '    ng-disabled="readOnly || formioForm.submitting || (component.disableOnInvalid && formioForm.$invalid) || hasError()"\n' +
            '    tabindex="{{ component.tabindex || 0 }}"\n' +
            '    ng-click="$emit(\'buttonClick\', component, componentId)" translate="{{component.translationId}}">\n' +
            '  <span ng-if="component.leftIcon" class="{{ component.leftIcon }}" aria-hidden="true"></span>\n' +
            '  <span ng-if="component.leftIcon && component.label">&nbsp;</span>{{ component.label | formioTranslate:null:options.building | shortcut:component.shortcut }}<span ng-if="component.rightIcon && component.label">&nbsp;</span>\n' +
            '  <span ng-if="component.rightIcon" class="{{ component.rightIcon }}" aria-hidden="true"></span>\n' +
            '  <formio-component-tooltip></formio-component-tooltip>\n' +
            '  <i ng-if="component.action == \'submit\' && formioForm.submitting" class="glyphicon glyphicon-refresh glyphicon-spin"></i>\n' +
            '</button>\n' +
            '<div ng-if="hasError()" class="has-error">\n' +
            '  <p class="help-block" onclick="scroll=window.scrollTo(0,0)">\n' +
            '    Please correct all errors before submitting.\n' +
            '  </p>\n' +
            '</div>\n'
        );
    }
]);

/**
 * override file component template to make it translation ready
 */
app.run([
    '$templateCache',
    '$interpolate',
    function ($templateCache, $interpolate) {
        $templateCache.put('formio/components/file.html',
            '<label ng-if="labelVisible() && (component.labelPosition !== \'bottom\')" for="{{ componentId }}" class="control-label" ng-class="{\'field-required\': isRequired(component)}" ng-style="getLabelStyles(component)">' +
            '  {{ component.label | formioTranslate:null:options.building }}' +
            '  <formio-component-tooltip></formio-component-tooltip>' +
            '</label>' +
            '<span ng-if="!component.label && isRequired(component)" class="glyphicon glyphicon-asterisk form-control-feedback field-required-inline" aria-hidden="true"></span>' +
            '<div class="formio-errors"><formio-errors ng-if="::!options.building"></formio-errors></div>' +
            '<div ng-controller="formioFileUpload" ng-style="getInputGroupStyles(component)">' +
            '  <formio-file-list files="data[component.key]" form="formio.formUrl" ng-if="!component.image" ng-required="isRequired(component)" ng-model="data[component.key]" ng-model-options="{allowInvalid: true}" name="{{ componentId }}" read-only="readOnly"></formio-file-list>' +
            '  <formio-image-list files="data[component.key]" form="formio.formUrl" width="component.imageSize" ng-if="component.image" ng-required="isRequired(component)" ng-model="data[component.key]" ng-model-options="{allowInvalid: true}" name="{{ componentId }}" read-only="readOnly"></formio-image-list>' +
            '  <div ng-if="!readOnly && (component.multiple || (!component.multiple && !data[component.key].length))">' +
            '    <div ngf-drop="upload($files, $invalidFiles)"' +
            '      class="fileSelector"' +
            '      ngf-drag-over-class="\'fileDragOver\'"' +
            '      ngf-pattern="component.filePattern"' +
            '      ngf-min-size="component.fileMinSize"' +
            '      ngf-max-size="component.fileMaxSize"' +
            '      ngf-multiple="component.multiple"' +
            '      id="{{ componentId }}"' +
            '      name="{{ componentId }}">' +
            '      <span class="glyphicon glyphicon-cloud-upload"></span><span translate="form-fileupload-text-1">Drop files to attach, or</span>' +
            '      <a style="cursor: pointer;"' +
            '        ng-keypress="browseKeyPress($event)"' +
            '        ng-attr-id="{{component.key}}-browse"' +
            '        ngf-select="upload($files, $invalidFiles)"' +
            '        tabindex="{{ component.tabindex || 0 }}"' +
            '        ngf-pattern="component.filePattern"' +
            '        ngf-min-size="component.fileMinSize"' +
            '        ngf-max-size="component.fileMaxSize"' +
            '        ngf-multiple="component.multiple" translate="form-fileupload-text-2">browse</a>.' +
            '    </div>' +
            '    <div ng-if="!component.storage" class="alert alert-warning">No storage has been set for this field. File uploads are disabled until storage is set up.</div>' +
            '    <div ngf-no-file-drop>File Drag/Drop is not supported for this browser</div>' +
            '  </div>' +
            '  <div ng-repeat="fileUpload in fileUploads track by $index" ng-class="{\'has-error\': fileUpload.status === \'error\'}" class="file">' +
            '    <div class="row">' +
            '      <div class="fileName control-label col-sm-10">{{ fileUpload.originalName || fileUpload.name }} <span ng-click="removeUpload(fileUpload.name)" class="glyphicon glyphicon-remove"></span></div>' +
            '      <div class="fileSize control-label col-sm-2 text-right">{{ fileSize(fileUpload.size) }}</div>' +
            '    </div>' +
            '    <div class="row">' +
            '      <div class="col-sm-12">' +
            '        <span ng-if="fileUpload.status === \'progress\'">' +
            '          <div class="progress">' +
            '            <div class="progress-bar" role="progressbar" aria-valuenow="{{fileUpload.progress}}" aria-valuemin="0" aria-valuemax="100" style="width:{{fileUpload.progress}}%">' +
            '              <span class="sr-only">{{fileUpload.progress}}% Complete</span>' +
            '            </div>' +
            '          </div>' +
            '        </span>' +
            '        <div ng-if="!fileUpload.status !== \'progress\'" class="bg-{{ fileUpload.status }} control-label">{{ fileUpload.message }}</div>' +
            '      </div>' +
            '    </div>' +
            '  </div>' +
            '</div>' +
            '<label ng-if="labelVisible() && (component.labelPosition === \'bottom\')" for="{{ componentId }}" class="control-label control-label--bottom" ng-class="{\'field-required\': isRequired(component)}">' +
            '  {{ component.label | formioTranslate:null:options.building }}' +
            '  <formio-component-tooltip></formio-component-tooltip>' +
            '</label>');
    }
]);

/**
 * mytooltip formio component
 */
app.run([
    '$templateCache',
    '$interpolate',
    function($templateCache, $interpolate) {
        $templateCache.put('formio/components/mytooltip.html',
            '<mytooltip component="component" data="data"></mytooltip>'
        );
    }
]);

app.directive('mytooltip', ['$parse', '$compile', '$translate', function($parse, $compile, $translate) {
    return {
        restrict: 'EA',
        replace: true,
        scope: {
            component:'=',
            data:'=',
        },
        link: function(scope, element, attrs, controller) {
            var component = $parse(attrs.component)(scope);
            var settings = {html:0 , trigger: 'hover', animation: false, placement: 'auto', container: 'body'};
            element = $(element);
            $translate(component.title, scope.data).then(function (translation_text) {
                /*jquery html falls apart if ending tag has whitespaces in and ruins the html */
                element.html('<a data-tippy-content="' + translation_text + '" href="#" class="btn-icon-toggle btn ink-reaction btn-default ' +
                    'btn-xs" title="' + translation_text + '"><i class="md md-info md-lg"></i> </a>');
                $compile(element.contents())(scope);
            }, function (translation_id) {
                element.html('<a data-tippy-content="' + translation_id + '"" href="#" class="btn-icon-toggle btn ink-reaction btn-default ' +
                    'btn-xs" title="' + translation_id + '"><i class="md md-info md-lg"></i> </a>');
                $compile(element.contents())(scope);
            });
        }
    };
}]);


/**
 * overwrite formio component template to add 'has-success' class
 */
app.run([
    '$templateCache',
    '$interpolate',
    'FormioUtils',
    function($templateCache, $interpolate, FormioUtils) {
        // A formio component template.
        $templateCache.put('formio/component.html',
          "<div class=\"form-group form-field-type-{{ component.type }} formio-component-{{ component.key }} {{component.customClass}}\" id=\"form-group-{{ componentId }}\"" +
            "\n     ng-class=\"{'has-feedback ': (component.hideLabel === true || component.label === '' || !component.label) && component.validate.required," +
            "\n             'has-error': (formioForm[componentId].$invalid || invalidQuestions(formioForm)) && !formioForm[componentId].$pristine," +
            "\n               'has-success': formioForm[componentId].$valid && !invalidQuestions(formioForm) && !formioForm[componentId].$pristine && !formioForm[componentId].$isEmpty(data[component.key]) }\"" +
            "\n     ng-style=\"component.style\"" +
            "\n     ng-hide=\"component.hidden\">" +
            "\n  <formio-element></formio-element>" +
            "\n</div>" +
            "\n\n"
        );
        // A formio formio/components/textfield.html template.
        $templateCache.put('formio/components/textfield.html', FormioUtils.fieldWrap(
          "<input\n  type=\"{{ component.inputType }}\"" +
            "\n  class=\"form-control\"" +
            "\n  id=\"{{ componentId }}\"" +
            "\n  name=\"{{ componentId }}\"" +
            "\n  tabindex=\"{{ component.tabindex || 0 }}\"" +
            "\n  ng-disabled=\"readOnly\"" +
            "\n  ng-model=\"data[component.key]\"" +
            "\n  ng-model-options=\"{ debounce: 500 }\"" +
            "\n  safe-multiple-to-single" +
            "\n  ng-required=\"isRequired(component)\"" +
            "\n  ng-minlength=\"component.validate.minLength\"" +
            "\n  ng-maxlength=\"component.validate.maxLength\"" +
            "\n  ng-pattern=\"component.validate.pattern\"" +
            "\n  custom-validator=\"component.validate.custom\"" +
            "\n  link-validator" +
            "\n  ng-attr-placeholder=\"{{ component.placeholder | formioTranslate:null:builder }}\"" +
            "\n  ui-mask=\"{{ component.inputMask }}\"" +
            "\n  ui-mask-placeholder=\"\"" +
            "\n  " +
            "ui-options=\"uiMaskOptions\"" +
            "\n>\n"
        ));
    }
]);


app.directive('popover', ['$translate', '$compile', function($translate, $compile) {
    return {
        restrict: 'A',
        link: function (scope, ele, attrs) {

            $(ele).on('mouseenter', function() {
                scope.label = attrs.dataTitle;
                var content = attrs.content;
                if (!content) {
                    content = jQuery('#'+attrs.contentId).html();
                } else {
                    content = jQuery(content);
                }

                var el = $(ele);
                el.data('hasOwnProperty', Object.prototype.hasOwnProperty);
                el.popover({
                    trigger: 'manual',
                    html: true,
                    content: content,
                    placement: (attrs.popoverPlacement !== undefined && attrs.popoverPlacement.length) ? attrs.popoverPlacement: 'auto',
                    container: 'body'
                });
                el.popover('show');

                $compile(jQuery('.popover'))(scope);


                $('.popover').on("mouseleave", function () {
                    if (!$(".popover:hover").length) {
                        // we only hide, when we are not hovering on the popup
                        //   someone might want to do something with the text on popup
                        jQuery('.popover').popover("hide");
                    }
                });

            }).on('mouseleave', function() {
                setTimeout(function () {
                    if (!$(".popover:hover").length) {
                        // we only hide, when we are not hovering on the popup
                        //   someone might want to do something with the text on popup
                        jQuery(ele).popover("hide");
                    }
                }, 300);
            });
        }
    };
}]);

app.directive('tooltip', ['$translate', '$compile', function($translate, $compile) {
    return {
        restrict: 'A',
        scope: {
            titleModel: '='
        },
        link: function (scope, ele, attrs) {
            var el = $(ele);
            el.data('hasOwnProperty', Object.prototype.hasOwnProperty);
            if(scope.titleModel) {
                el.attr('data-original-title', scope.titleModel);
            }
            
            el.tooltip();
            scope.element = ele;

            // if the title changes the update the tooltip
            scope.$watch('titleModel', function(newTitle) {
                if (scope.element) {
                    $(scope.element).attr('data-original-title', newTitle);
                    var el = $(scope.element);
                    el.data('hasOwnProperty', Object.prototype.hasOwnProperty);
                    el.tooltip();
                }
            });
        }
    };
}]);

app.directive('translate_xx', ['$translate', function($translate) {
    if(window.self !== window.top) {
        var ALL_TRANSLATE_ELEMENTS = [];
        var ALL_TRANSLATE_TEXTS = [];
        var sendTranslateableTexts;

        $translate._originalInstant = $translate.instant;
        $translate.instant = function(text) {
            var trans = {};
            trans['key'] = text;
            var translatedText = $translate._originalInstant(text);
            trans['translation'] = translatedText;
            ALL_TRANSLATE_TEXTS.push(trans);
            return translatedText;
        };

        $(window).on("message onmessage", function (e) {
            var data = e.originalEvent.data;
            if (data.action && data.action === 'get_all_translations') {
                console.log('Received get_all_translations event');
                sendTranslateableTexts();
            }
        });

        sendTranslateableTexts = function() {
            var translations = [];
            var existing = {};
            ALL_TRANSLATE_ELEMENTS.forEach(function (element) {
                var transId = $(element).attr('translate');
                if(!transId) {
                    transId = $(element).text();
                }
                if (!transId) {
                    return;
                }
                if (transId in existing) {
                    return;
                }
                existing[transId] = true;

                var transText = $(element).text();
                var trans = {
                    "data_type": "text",
                    "key": transId,
                    "translation": transText,
                    "lang": CURRENT_LANGUAGE
                }
                translations.push(trans);
            });

            ALL_TRANSLATE_TEXTS.forEach(function(text) {
                if (text.key in existing) {
                    return;
                }

                existing[text.key] = true;
                var trans = {
                    "data_type": "text",
                    "key": text.key,
                    "translation": text.translation,
                    "lang": CURRENT_LANGUAGE
                }
                translations.push(trans);
            });
            window.parent.postMessage(JSON.stringify({'action': 'get_all_translations_resp', 'translations': translations}), '*');
        }
        $(document).ready(function() {
            sendTranslateableTexts();
        });
    }

    return {
        restrict: 'A',
        link: function (scope, ele, attrs) {
            if(window.self === window.top) {
                return;
            }
            var translationId = attrs.translate;

            ALL_TRANSLATE_ELEMENTS.push(ele);

            if(!translationId) {
                return;
            }

            $(ele).on('mouseenter', function(event) {
                scope.label = attrs.dataTitle;

                $(ele).popover({
                    trigger: 'manual',
                    html: true,
                    content: '<span class="popover-trans-text" data-translation-id="'+translationId+'">Double click to edit translation</span>',
                    placement: 'auto left',
                    container: 'body'
                });

                $(ele).popover('show');

                $('.popover-trans-text').on("click", function (event) {
                    var translateText = $(ele).text();
                    var data = {
                        "action": "translation_edit",
                        "data_type": "text",
                        "key": translationId,
                        "translation": translateText,
                        "lang": CURRENT_LANGUAGE
                    };
                    console.log('Translate message to BPA', data);
                    window.parent.postMessage(data, "*");
                });
            });


            $(ele).on('mouseout', function(event) {
                setTimeout(function () {
                if (!$(".popover:hover").length) {
                    $(ele).popover("hide");
                }
                }, 300);
            });

            if (translationId !== undefined && translationId.length) {
                $(ele).on("dblclick", function (event) {
                    event.preventDefault();

                    var translateText = $(ele).text();
                    var data = {
                        "action": "translation_edit",
                        "data_type": "text",
                        "key": translationId,
                        "translation": translateText,
                        "lang": CURRENT_LANGUAGE
                    };
                    console.log('Translate message to BPA', data);
                    window.parent.postMessage(data, "*");

                    return false;
                });
            }
        }
    };
}]);


/**
 * myecoactivity formio component
 *
 * type: myecoactivity
 *
 * configure component:
 *   key - normal formio api key
 *   iframe_url - the url of the service in iframe. default 'https://els.eregistrations.org/economic-activities/'
 *
 */
app.run([
    '$templateCache',
    '$interpolate',
    function($templateCache, $interpolate) {
        $templateCache.put('formio/components/myecoactivity.html',
            '<div myecoactivity formio-form="formioForm" formio="formio" component="component" data="data" formio-form="formioForm" submission="submission" id="{{ componentId }}" name="{{ componentId }}" ng-model="data[component.key]" custom-validator="component.validate.custom" component-id="componentId"></div>' +
            '<div><p class="help-block" ng-show="formioForm[componentId].$invalid">{{ component.customError | formioTranslate }}</p></div>\n');
    }
]);

app.directive('myecoactivity', function() {
    return {
        restrict: 'A',
        require: '?ngModel',
        scope: {
            component:'=',
            data:'=',
            submission: '=',
            formioForm: '=',
            componentId: '=',
            formio: '=',
            ngModel: '='
        },
        template: '<div class="economic-activities" ng-required="isRequired(component)">' +
        '<label for="economicActivitiesList-{{componentKey}}" ng-if="(options.building || (component.label && !component.hideLabel)) && component.labelPosition !== \'bottom\'" ng-class="{ \'field-required\': isRequired(component) }" ng-style="getLabelStyles(component)">{{component.label}}</label>' +
		'<button type="button" ng-click="openIframe()" class="btn btn-primary" data-toggle="modal" data-target="#myEcoActivity-{{componentKey}}" translate="button-choose-economic-activity">Choose economic activity</button>' +
		'<ul id="economicActivitiesList-{{componentKey}}"><li ng-repeat="value in value_list">{{value.label}}</li></ul>' +
        '  <div class="modal fade" id="myEcoActivity-{{componentKey}}" role="dialog">\n' +
        '    <div class="contain-lg">\n' +
        '      <!-- Modal content-->\n' +
        '      <div class="card card-underline">\n' +
		'        <div class="modal-header">\n' +
		'          <h4>{{component.title}}</h4>\n' +
		'          <button type="button" class="close" data-dismiss="modal"><span>&times;</span></button>\n' +
        '        </div>\n' +
        '        <div class="modal-body"><iframe style="width: 100%; height: 600px; border: 0;" ng-src="{{trusted_url}}"></iframe>' +
        '        </div>' +
        '    </div>\n' +
        '  </div></div>',
        controller: ['$scope', '$sce', '$window', '$http', 'FormioUtils', '$attrs', '$interpolate', function($scope, $sce, $window, $http, FormioUtils, $attrs, $interpolate) {
            var self = this;
            Object.assign($scope, FormioUtils);
            var component = $scope.component ? $scope.component : {};
            if (!component.key) throw new Error("Components must have unique keys, none given!");
            var iframe_url = component.iframe_url ? component.iframe_url : 'https://els.eregistrations.org/economic-activities/';
            $scope.componentKey = component.key;

            this.$name = $interpolate($attrs.name || $attrs.ngForm || '')($scope);
            $scope.formioForm[$scope.componentKey] = this;

            iframe_url += '?msg_key=' + encodeURIComponent($scope.componentKey);

            $scope.trusted_url = '';

            if ($scope.componentKey && $scope.data[$scope.componentKey]) {
                $scope.value_list = $scope.data[$scope.componentKey];
            } else {
                $scope.value_list = [];
            }

            this.$isEmpty = function(value) {
                return $scope.ziModel.$isEmpty(value);
            };

            Object.defineProperty($scope.formioForm[$scope.componentKey], '$valid', {
                get: function() {
                    return $scope.ziModel.$valid;
                }
              });

            Object.defineProperty($scope.formioForm[$scope.componentKey], '$invalid', {
                get: function() {
                    return $scope.ziModel.$invalid;
                }
              });

            this.$pristine = true;

            $window.addEventListener('message', function (e) {
                var data = e.data;
                if (!data) {
                    return;
                }
                if (data.msg_key !== $scope.componentKey) {
                    return;
                }

                $scope.value_list = [];
                angular.forEach(data.values_list, function (val) {
                    if (!val || !val.key || !val.label) {
                        return;
                    }
                    $scope.value_list.push({ 'key': val.key, 'label': val.label });
                });

                if ($scope.componentKey && $scope.data) {
                    $scope.data[$scope.componentKey] = $scope.value_list;
                    self.$pristine = false;
                }
                $('#myEcoActivity-' + $scope.componentKey).modal('hide');
                $scope.$apply();
            });

            $scope.openIframe = function () {
                $scope.trusted_url = $sce.trustAsResourceUrl(
                    $scope.value_list.length ? iframe_url + '&' + $scope.value_list.map(function (val) {
                        var res = "selected=" + encodeURIComponent(val.key);
                        if (val.key === "000000") {
                            res += "&customValue=" + encodeURIComponent(val.label);
                        }
                        return res;
                    }).join("&") : iframe_url
                );
            };
        }],
        link: function (scope, element, attrs, ngModel) {
            // linking with ngModel is necessary, because there is where the validation happens
            scope.ziModel = ngModel;
        }
    };
});


/**
 * cubanproductlist formio component
 *
 * type: cubanproductlist
 *
 * configure component:
 *   key - normal formio api key
 *   iframe_url - the url of the service in iframe
 *
 */
app.run([
    '$templateCache',
    '$interpolate',
    function($templateCache, $interpolate) {
        $templateCache.put('formio/components/cubanproductlist.html',
            '<div formio-form="formioForm" formio="formio" component="component" data="data" formio-form="formioForm" submission="submission" id="{{ componentId }}" name="{{ componentId }}" ng-model="data[component.key]" custom-validator="component.validate.custom" component-id="componentId" cubanproductlist></div>' +
            '<div class="formio-errors"><p class="help-block" ng-show="formioForm[componentId].$invalid">{{ component.customError }}</p></div>');
    }
]);

app.directive('cubanproductlist', function() {
    return {
        restrict: 'A',
        require: '?ngModel',
        scope: {
            component:'=',
            data:'=',
            submission: '=',
            formioForm: '=',
            componentId: '=',
            formio: '=',
            ngModel: '='
        },
        template: '<div class="component-product-list-container" ng-required="isRequired(component)">' +
        '<label for="cubanProductList-{{componentKey}}" ng-if="(options.building || (component.label && !component.hideLabel)) && component.labelPosition !== \'bottom\'" ng-class="{ \'field-required\': isRequired(component) }" ng-style="getLabelStyles(component)">{{component.label}}</label>' +
		'<button type="button" ng-click="openIframe(); resizableLayers();" class="btn btn-primary" data-toggle="modal" data-target="#cubanproductlist-{{componentKey}}">{{ buttonLabel }}</button>' +
        '<ul id="cubanProductList-{{componentKey}}" class="component-product-list {{value_list.length < 1 ? \'empty-list\' : \'\'}}">' +
        '   <li ng-repeat="value in value_list"><div class="component-product-list-row"><span class="component-product-list-id">{{value.id}}</span><span class="component-product-list-name">{{value.name}}</span><span class="component-product-list-date badge badge-default">{{value.date || ""}}</span></div>'+
                  '<ul class="list-unstyled p-0" ng-if="value.children && value.children.length">' +
                    '<li ng-repeat="val2 in value.children">' +
                        '<div class="component-product-list-row"><span class="component-product-list-id">{{val2.id}}</span><span class="component-product-list-name">{{val2.name}}</span><span class="component-product-list-date badge badge-default">{{ val2.date || "" }}</span></div>'+
                        '<ul class="list-unstyled p-0" ng-if="val2.children && val2.children.length">' +
                            '<li ng-repeat="val3 in val2.children">' +
                                '<div class="component-product-list-row"><span class="component-product-list-id">{{val3.id}}</span><span class="component-product-list-name">{{val3.name}}</span><span class="component-product-list-date badge badge-default">{{ val3.date || "" }}</span></div>'+
                                '<ul class="list-unstyled p-0" ng-if="val3.children && val3.children.length">' +
                                    '<li ng-repeat="val4 in val3.children">' +
                                        '<div class="component-product-list-row"><span class="component-product-list-id">{{val4.id}}</span><span class="component-product-list-name">{{val4.name}}</span><span class="component-product-list-date badge badge-default">{{ val4.date || "" }}</span></div>'+
                                    '</li>' +
                                '</ul>' +
                            '</li>' +
                        '</ul>' +
                    '</li>' +
                  '</ul>' +
                '</li>' +
        '</ul>' +
        '  <div class="modal fade component-product-list-modal" id="cubanproductlist-{{componentKey}}" role="dialog">\n' +
        '    <div class="modal-dialog modal-dialog-centered modal-lg resizable-dialog">\n' +
        '      <div class="resizer"><span></span></div>\n' +
        '      <!-- Modal content-->\n' +
        '      <div class="modal-content">\n' +
        '        <div class="modal-header py-0">\n' +
        '           <h5 class="modal-title d-inline-block my-1" translate="Product list">lista de productos</h5>\n' +
        '          <button type="button" class="close mt-2" data-dismiss="modal"><span>&times;</span></button>\n' +
        '        </div>' +
        '        <div class="modal-body py-0">\n' +
        '          <iframe id="cubanproductlist-iframe-{{component.key}}" style="width: 100%; height: 620px; border: 0;" ng-src="{{trusted_url}}"></iframe>' +
        '        </div>' +
        '    </div>\n' +
        '  </div></div>',
        controller: ['$scope', '$sce', '$window', '$http', 'FormioUtils', '$attrs', '$interpolate', '$translate', function($scope, $sce, $window, $http, FormioUtils, $attrs, $interpolate, $translate) {
            var self = this;
            this.isChannelOpen = false;
            Object.assign($scope, FormioUtils);
            var component = $scope.component ? $scope.component : {};
            if (!component.key) throw new Error("Components must have unique keys, none given!");
            var childEditGrid = null;
            if (component.components && component.components[0] && component.components[0].type === "editgrid") {
                childEditGrid = component.components[0];
                component.validate = childEditGrid.validate;
                component.validate.required = true;
            }

            var iframe_url = component.iframe_url ? component.iframe_url : 'https://admin-wip-cuba.eregulations.org/Views/Widget/Default.aspx?msg_key=' + component.key;
            $scope.componentKey = component.key;
            $scope.buttonLabel = $translate.instant("Choose product");
            var sendData = function () {
                var iframe = document.getElementById('cubanproductlist-iframe-' + component.key);
                if (!iframe) return;
                var dataToSend = Helpers.productListTreeToList(JSON.parse(JSON.stringify($scope.value_list)));
                console.log("SENDING DATA: ", JSON.stringify(dataToSend));
                iframe.contentWindow.postMessage(dataToSend, "*");
            }

            this.$name = $interpolate($attrs.name || $attrs.ngForm || '')($scope);
            // no formioForm on scope in partB
            if (!$scope.formioForm) {
                $scope.formioForm = {};
            } else {
                $scope.formioForm.$addControl(this);
            }

            $scope.trusted_url = '';

            // For the mapping purpose, we store data in the list format (not tree) for a linked component (prefixed with 'hiddenEditGrid-')
            if ($scope.componentKey && childEditGrid && $scope.data[childEditGrid.key]) {
                var mappedProductList = $scope.data[childEditGrid.key].map(function (item) {
                     var mappedObj = {};
                     Object.keys(item).forEach(function (propName) {
                         mappedObj[propName.replace(/\d+$/, "")] = item[propName]});
                    return mappedObj;
                });
                $scope.value_list = Helpers.productListToTree(mappedProductList);
            } else {
                $scope.value_list = [];
            }
            this.$isEmpty = function(value) {
                return $scope.ziModel.$isEmpty(value);
            };

            if ($scope.formioForm[$scope.componentKey]) {
                Object.defineProperty($scope.formioForm[$scope.componentKey], '$valid', {
                    get: function () {
                        return $scope.ziModel.$valid;
                    }
                });

                Object.defineProperty($scope.formioForm[$scope.componentKey], '$invalid', {
                    get: function () {
                        return $scope.ziModel.$invalid;
                    }
                });
            }

            $window.addEventListener('message', function (e) {
                console.log("MESSAGE RECIEVED", e);
                try {
                    var data = JSON.parse(e.data);
                    if (data.data) {
                        data.data = JSON.parse(data.data);
                    }
                } catch (e) {
                    console.log("malformed event from component", e);
                }
                if (!data) {
                    return;
                }
                if (data.msg_key !== component.key) {
                    return;
                }
                
                if (data.status === 'ready') {
                    console.log("Channel open");
                    self.isChannelOpen = true;
                    sendData();
                    return;
                }
                if (data.status !== 'selected' || !data.data) {
                    return;
                }
                console.log("Received data");


                $scope.value_list = Helpers.productListToTree(data.data);

                if ($scope.componentKey && $scope.data) {
                    $scope.data[$scope.componentKey] = $scope.value_list;
                    // sorting may not be the best in the received data - hence we save from the tree, not directly from the original data
                    if (childEditGrid) {
                        // ugly stuff
                        // get the components inside the edit grid and try matching them 
                        var productListLinearObject = Helpers.productListTreeToList(JSON.parse(JSON.stringify($scope.value_list)));
                        // needed props
                        var editGridProps = (childEditGrid.components || []).map(function (el) { return el.key});

                        $scope.data[childEditGrid.key] = productListLinearObject.map(function (item) { 
                            var mappedObj = {};
                            editGridProps.forEach(function (propName) { 
                                mappedObj[propName]=item[propName.replace(/\d+$/, "")]; 
                            }); 
                            return mappedObj;
                        });
                    }

                    self.$pristine = false;
                }
                $('#cubanproductlist-' + $scope.componentKey).modal('hide');
                $scope.$apply();
            });

            $scope.openIframe = function () {       
                $scope.trusted_url = $sce.trustAsResourceUrl(iframe_url);
                if (self.isChannelOpen) {
                    sendData();
                }
            };

            $scope.resizableLayers = function() {

                var resizableElementSelector = '.resizable-dialog';
                var resizableSelector = '.resizable-dialog .resizer';
                var resizingElement = null;
                var resizing = false;
                var resizingDirection = '';
                var currentPosition = 0;
                
                $(resizableElementSelector).unbind();
                $(resizableSelector).unbind();
                
                $(resizableSelector).on("mousedown", function (e) {
                    resizing = true;
                    currentPosition = e.pageX;
                    resizingElement = $(this).closest(resizableElementSelector);
                    resizingDirection = resizingElement.attr('resizing-direction') ? resizingElement.attr('resizing-direction') : '';
                });
                
                $(document, resizableSelector, resizableElementSelector).on("mouseup", function () {
                    resizing = false;
                    resizingElement = null;
                });

                $(document).on("mousemove", function (e) {
                    if (resizing == true && resizingElement != null) {
                        var positionDiference = currentPosition - e.pageX;
                        var elementWidth = resizingElement.width();
                        var nextWidth = resizingDirection == ''
                                        ? elementWidth + positionDiference
                                        : elementWidth - positionDiference;
                        resizingElement.width(nextWidth);
                        currentPosition = e.pageX;
                    }
                });
            }
        }],
        link: function (scope, element, attrs, ngModel) {
            // linking with ngModel is necessary, because there is where the validation happens
            // we assign conditianlly because in part b therre is no scope.formioForm
            if (scope.formioForm) {
                ngModel.$$parentForm = scope.formioForm;
            }
            scope.ziModel = ngModel;
        }
    };
});


/**
 * cubanproduclistview formio component
 *
 * Read-only view of cubanproductlist
 *
 * type: cubanproduclistview
 *
 * configure component:
 *   key - normal formio api key
 */
app.run([
    '$templateCache',
    '$interpolate',
    function($templateCache, $interpolate) {
        $templateCache.put('formio/components/cubanproductlistview.html',
            '<div cubanproductlistview formio-form="formioForm" formio="formio" component="component" data="data" formio-form="formioForm" submission="submission" id="{{ componentId }}" name="{{ componentId }}" ng-model="data[component.key]" custom-validator="component.validate.custom" component-id="componentId"></div>' +
            '<div><p class="help-block" ng-show="formioForm[componentId].$invalid">{{ component.customError | formioTranslate }}</p></div>\n');
    }
]);

app.directive('cubanproductlistview', function() {
    return {
        restrict: 'A',
        require: '?ngModel',
        scope: {
            component:'=',
            data:'=',
            submission: '=',
            formioForm: '=',
            componentId: '=',
            formio: '=',
            ngModel: '='
        },
        template: '<div class="component-product-list-container" ng-required="isRequired(component)">' +
        '<label for="cubanProductList-{{componentKey}}" ng-if="(options.building || (component.label && !component.hideLabel)) && component.labelPosition !== \'bottom\'" ng-class="{ \'field-required\': isRequired(component) }" ng-style="getLabelStyles(component)">{{component.label}}</label>' +
        '<ul id="cubanProductListView-{{componentKey}}" class="component-product-list {{value_list.length < 1 ? \'empty-list\' : \'\'}}">' +
        '   <li ng-repeat="value in value_list"><span class="component-product-list-id">{{value.id}}</span><span class="component-product-list-name">{{value.name}}</span><span class="component-product-list-date badge badge-default">{{value.date}}</span>'+
                  '<ul ng-if="value.children && value.children.length">' +
                    '<li ng-repeat="val2 in value.children">' +
                        '<span class="component-product-list-id">{{val2.id}}</span><span class="component-product-list-name">{{val2.name}}</span><span class="component-product-list-date badge badge-default">{{ val2.date || "" }}</span>'+
                        '<ul ng-if="val2.children && val2.children.length">' +
                            '<li ng-repeat="val3 in val2.children">' +
                                '<span class="component-product-list-id">{{val3.id}}</span><span class="component-product-list-name">{{val3.name}}</span><span class="component-product-list-date badge badge-default">{{ val3.date || "" }}</span>'+
                                '<ul ng-if="val3.children && val3.children.length">' +
                                    '<li ng-repeat="val4 in val3.children">' +
                                        '<span class="component-product-list-id">{{val4.id}}</span><span class="component-product-list-name">{{val4.name}}</span><span class="component-product-list-date badge badge-default">{{ val4.date || "" }}</span>'+
                                    '</li>' +
                                '</ul>' +
                            '</li>' +
                        '</ul>' +
                    '</li>' +
                  '</ul>' +
                '</li>' +
        '</ul>',
        controller: ['$scope', '$sce', '$window', '$http', 'FormioUtils', '$attrs', '$interpolate', '$translate', function($scope, $sce, $window, $http, FormioUtils, $attrs, $interpolate, $translate) {
            Object.assign($scope, FormioUtils);
            var component = $scope.component ? $scope.component : {};
            if (!component.key) throw new Error("Components must have unique keys, none given!");
            $scope.componentKey = component.key;
            var childEditGrid = null;
            if (component.components && component.components[0] && component.components[0].type === "editgrid") {
                childEditGrid = component.components[0];
            }

            this.$name = $interpolate($attrs.name || $attrs.ngForm || '')($scope);
            if ($scope.formioForm) {
                $scope.formioForm[$scope.componentKey] = this;
            }

            if ($scope.componentKey && childEditGrid && $scope.data[childEditGrid.key]) {
                $scope.value_list = Helpers.productListToTree($scope.data[childEditGrid.key]);
            // maintain backwards compatibility in case there is not child edit grid
            } else if ($scope.componentKey && !(childEditGrid && $scope.data[childEditGrid.key])) {
                $scope.value_list = $scope.data[$scope.componentKey];
            } else {
                $scope.value_list = [];
            }
        }],
        link: function (scope, element, attrs, ngModel) {
            // linking with ngModel is necessary, because there is where the validation happens
            scope.ziModel = ngModel;
        }
    };
});


/**
 * qrcode formio component
 *
 * type: qrcode
 *
 * configure component:
 *   key - normal formio api key
 *
 */
app.run([
    '$templateCache',
    '$interpolate',
    function($templateCache, $interpolate) {
        $templateCache.put('formio/components/qrcode.html',
            '<div qrcode formio-form="formioForm" formio="formio" component="component" data="data" formio-form="formioForm" submission="submission" id="{{ componentId }}" name="{{ componentId }}" component-id="componentId"></div>');
    }
]);

app.directive('qrcode', function() {
    return {
        restrict: 'A',
        scope: {
            component:'=',
            data:'=',
            submission: '=',
            formioForm: '=',
            componentId: '=',
            formio: '=',
        },
        template: '<div class="data-qr">' +
                    '<div>' +
                      '<img src="{{ component.qrImageSrc }}" />' +
                    '</div>' +
                    '</div>',
    };
});


/**
 * qrcode formio component
 *
 * type: qrcode
 *
 * configure component:
 *   key - normal formio api key
 *
 */
app.run([
    '$templateCache',
    '$interpolate',
    function($templateCache, $interpolate) {
        $templateCache.put('formio/components/barcode.html',
            '<div barcode formio-form="formioForm" formio="formio" component="component" data="data" formio-form="formioForm" submission="submission" id="{{ componentId }}" name="{{ componentId }}" component-id="componentId"></div>');
    }
]);
app.directive('barcode', function() {
    return {
        restrict: 'A',
        scope: {
            component:'=',
            data:'=',
            submission: '=',
            formioForm: '=',
            componentId: '=',
            formio: '=',
        },
        template: '<div class="data-barcode">' +
                    '<div>' +
                      '<img src="data:image/png;base64,{{ component.barcodeImageSrc }}" />' +
                    '</div>' +
                    '</div>',
    };
});

app.directive('postTemplateRender', ['$rootScope', '$timeout', function($rootScope, $timeout) {
    return {
        restrict: 'A',
        terminal : true,
        transclude: false,
        link: function(scope, ele, attrs) {
        },
        compile: function() {
            $timeout(function() {
                $rootScope.$emit('maybeDoneLoading');
            }, 0);
        }
    }
}]);
