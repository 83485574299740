
app.config([
    'formioComponentsProvider',
    function(formioComponentsProvider) {
      formioComponentsProvider.register('mydetailtable', {
        title: 'My Detail Table',
        template: 'formio/components/mydetailtable.html',
        group: 'advanced',
        settings: {},
      });
    }
]);


app.run([
    '$templateCache',
    '$interpolate',
    function($templateCache, $interpolate) {
        $templateCache.put('formio/components/mydetailtable.html',
            "<mydetailtable component='component' data='data' submission='submission' form='form' ignore='ignore' builder='builder'></mydetailtable>"
        )
    }
]);

/**
 *mydetailtable component
 *
   displayFields = [
     {
       'label': 'Label2',
       'key': 'asd2',
       'valueCustomClass': 'col-sm-2 text-in-middle text-center',
       'labelCustomClass': 'text-center col-sm-2'
     }
   ];
   detailFields = [
     {
       'label': 'Label1',
       'key': 'asd1',
       'valueCustomClass': 'col-sm-2 text-in-middle text-center',
       'labelCustomClass': 'text-center col-sm-2'
     }
   ];
 */
app.directive('mydetailtable', ['$parse', '$compile', 'FormioUtils', '$translate', '$sce',
    function($parse, $compile, FormioUtils, $translate, $sce) {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            component:'=',
            data:'=',
            submission: '=',
        },
        template: '<table class="no-margin table table-bordered">\n' +
            '         <thead>\n' +
            '             <tr>\n' +
            '                 <th class="{{ f.labelCustomClass }}" id="usager-grid_c0" ng-repeat="f in component.displayFields">\n' +
            '                     {{ f.label }}\n' +
            '                 </th>\n' +
            '             </tr>\n' +
            '         </thead>\n' +
            '         <tbody>\n' +
            '             <tr ng-click="toggleRow(\'row\' + $index)" ng-repeat-start="item in theData">\n' +
            '                 <td ng-repeat="f in component.displayFields" class="{{ f.valueCustomclass }}">\n' +
            '                     {{ item[f.key] }}\n' +
            '                 </td>\n' +
            '             </tr>\n' +
            '             <tr id="row{{ $index }}" style="display: none;" ng-repeat-end="item in theData">\n' +
            '                 <td colspan="7">\n' +
            '                     <div class="row" ng-repeat="d in component.detailFields"><dt class="col-xs-4 text-right">{{ d.label }}</dt><div class="col-xs-8">{{ item[d.key] }}</div></div>\n' +
            '                 </td>\n' +
            '             </tr>\n' +
            '         </tbody>\n' +
            '     </table>',
        controller: ['$scope', 'FormioUtils', function($scope, FormioUtils) {
            $scope.rows = {};

            if ($scope.data) {
                $scope.theData = $scope.data;
            } else {
                $scope.theData = $scope.submission[$scope.component.key];
            }

            $scope.toggleRow = function (row) {
                $('#' + row).toggle('fast');
            }

        }]
    };
}]);