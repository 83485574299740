import jsonLogic from 'json-logic-extension';
import * as _ from 'lodash';
import tippy from 'tippy.js';
import 'formiojs-latest';

app.filter('capitalize', function() {
    return function(text) {
        return (!!text) ? text.charAt(0).toUpperCase() + text.substr(1).toLowerCase() : '';
    }
});
app.filter('show_boolean', function() {
    return function(text) {
        if (text === undefined){ return ""; }
        var data = {'true': 'Yes', 'false': 'No'};
        return (data[text.toString()] !== undefined) ? data[text] : text;
    }
});
app.filter('currencyFormat', function() {
    return function(text) {
        var n = parseFloat(text, 10);
        var x = n.toFixed(2).replace(/./g, function(c, i, a) {
            return i && c !== "." && ((a.length - i) % 3 === 0) ? "'" + c : c;
        });
        //always 2 places after comma
        return x;

        // if (x.indexOf('.00', x.length - 3) == -1) {
        //     return x;
        // } else {
        //     return x.replace('.00', '');
        // }
    }
});

/**
 * Remove duplicate file statuses and applies only the last one
 */
app.filter('uniqFileStatus', function() {
    return function(inp) {
        if (!inp) {
            return inp;
        }

        const reg = {};
        angular.forEach(inp, function(elem) {
            if (elem.name in reg) {
                reg[elem.name].hidden = true;
            }
            reg[elem.name] = elem;
        });

        return inp;
    }
})

app.service('Helpers', ['Backend', 'Notification', '$templateCache', '$location', '$translate', '$http', '$sce', '$q',
            function(Backend, Notification, $templateCache, $location, $translate, $http, $sce, $q) {
    var self = this;

    this.GLOBALS = {
        DATE_FORMAT: 'dd/MM/yyyy',
        DATE_TIME_FORMAT: 'dd/MM/yyyy, HH:mm'
    }

     /**
     * List sorting functions
     */
    this.sorters = function (obj_property, data_type) {
        data_type = typeof data_type !== 'undefined' ? data_type : 'default';

        var sorter = {
            default: function (a, b) {
                return ((a[obj_property] < b[obj_property]) ? -1 : ((a[obj_property] > b[obj_property]) ? 1 : 0));
            },
            date: function (a, b) {
                return new Date(a[obj_property]) - new Date(b[obj_property]);
            }
        };

        return sorter[data_type];
    };

    /**
     * change upload url
     */
    this.changeUploadUrl = function (data, file_id, url_part) {
        var string_data = JSON.stringify(data);
        var new_string_data = string_data.replace(/(http[s]?:\/\/[a-zA-Z0-9\.\-:_]+\/backend\/)(files\/)(generic)(\/document)/g,
            //Backend.backend_url + '$2' + self.file.file_id + '/certificate');
            Backend.backend_url + '$2' + file_id + url_part);

        return JSON.parse(new_string_data);
    };

    /**
     * change upload url
     */
    this.changeProcessUploadUrl = function (data, process_id, url_part) {
        var string_data = JSON.stringify(data);
        // process-certificate
        var new_string_data = string_data.replace(/(http[s]?:\/\/[a-zA-Z0-9\.\-:_]+\/backend\/)(files\/)(generic)(\/document)/g,
            //Backend.backend_url + '$2' + self.file.file_id + '/certificate');
            Backend.backend_url + 'process/' + process_id + url_part);

        var new_string_data = new_string_data.replace(/(http[s]?:\/\/[a-zA-Z0-9\.\-:_]+\/backend\/)(files\/)(generic)(\/certificate)/g,
            //Backend.backend_url + '$2' + self.file.file_id + '/certificate');
            Backend.backend_url + 'process/' + process_id + '$4');

        return JSON.parse(new_string_data);
    };

    /**
     * Process info functions
     */
    this.getCurrentRegStatusName = function(file) {
        var status_name = "";
        angular.forEach(file.process_states, function (reg_status) {
            if (reg_status.status == 'started') {
                /* we want long name but if doesn't exists, return short name */
                if (reg_status.shortname) {
                    status_name = reg_status.name;
                }
                if (status_name.length && reg_status.name) {
                    status_name = status_name+' - '+reg_status.name;
                } else if (reg_status.name){
                    status_name = reg_status.name;
                }
            }
        });
        return status_name;
    };

    /**
     * Returns a document/certificate key from the form
     *
     * @param file, document/certificate
     */
    this.getFileKey = function(data, document) {
        var return_value = null;
        angular.forEach(data, function (field, key) {
            if (field !== undefined && field !== null && field.length) {
                angular.forEach(field, function(f) {
                    if (f && f.name && f.name == document.document_id) {
                        return_value = key;
                    }
                })
            }
        });
        return return_value;
    };

    this.getFormTitle = function(form){
        if (form.components && form.components[0])
            return form.components[0].title;

        return "";
    };

    // TODO: do we need it?
    this.getRequiredForms = function(form_list){
        return form_list;
    };

    this._addErrorMessage = function(error_keys, error_messages, form_type, component_key, error_key) {
        var has_key = false;
        angular.forEach(error_messages[form_type], function (err) {
            if (err.error_key == error_key){ has_key = true; }
        });
        if (!has_key) {
            /* error_keys iclude only original component keys and replicated keys are not represented */
            var error_message = angular.copy(error_keys[component_key]);
            if (!error_message) {
                return;
            }
            error_message.error_key = error_key;
            if(!error_messages[form_type]) {
                error_messages[form_type] = [];
            }
            error_messages[form_type].push(error_message);
        }
    };


    this._getFormErrors = function(form, result) {
        if (!result) result = [];
        if (!form.$error) return result;


        Object.keys(form.$error).forEach(function (key) {
            if (!Array.isArray(form.$error[key])) return;
            form.$error[key].forEach(function (err) {
                result.push({ key: err.$name });
            });
        });


        return _.uniq(result);
    };

    this._map_error_fields = function(component, error_keys, last_title) {
        if(!component) {
            return;
        }
        var title = angular.copy(last_title); //make sure we are not overwriting it
        if (component.title !== undefined) { var title = component.title; }
        if (component.key !== undefined) {
            // var can_use_label = !(['select', 'radio', 'number'].indexOf(component.type) >= 0);
            if (component.label !== undefined && component.label.trim().length) {
                error_keys[component.key] = {key: component.key, label: component.label, type: component.type};
            } else {
                if (component.type == 'content') {
                    var html = component.html.trim();
                    title = String(html).replace(/<[^>]+>/gm, '');
                }
                if (component.type == 'htmlelement') {
                    title = component.content;
                }
                error_keys[component.key] = {key: component.key, label: title, type: component.type};
            }
        }

       angular.forEach(component.components, function(comp){
           self._map_error_fields(comp, error_keys, title);
       });
       angular.forEach(component.rows, function(row){
           angular.forEach(row, function(col){
               angular.forEach(col.components, function(comp){
                   self._map_error_fields(comp, error_keys, title);
               });
           });
       });
       angular.forEach(component.columns, function(col){
           angular.forEach(col.components, function(comp){
               self._map_error_fields(comp, error_keys, title);
           });
       });
    };

    this.mapFormErrorLabels = function(form_types, form_list) {
        var error_keys = {};
        angular.forEach(form_types, function (form_type) {
            var form = form_list[form_type];
            /*it is possible that we don't have the form specified....then we skip it*/
            if(form !== undefined) {
                if (angular.isArray(form)) {
                    angular.forEach(form, function (f) {
                        self._map_error_fields(f, error_keys);
                    });
                }
                else {
                    self._map_error_fields(form, error_keys);
                }
            }
        });
        angular.forEach(error_keys, function (error_key) {
            /* remap labels if we have match */
            var new_key = error_key.key+error_key.label.toLowerCase()+'Label';
            if (error_keys[new_key] !== undefined) {
                error_keys[error_key.key].label = error_keys[new_key].label;
            }
        });
        return error_keys;
    };

    /**
     * Returns a false in case of validation error
     * and true if form is valid.
     * Also shows error message with invalid fields
     *
     * @param form_types, $element, $scope
     * */
    this.checkFormErrors = function(form_types, $element, $scope, form_list/*, opts */) {
        var opts = Object(arguments[4]);
        var error_keys = self.mapFormErrorLabels(form_types, form_list);
        var show_notifications =  true;
        var has_errors = false;
        var error_messages = {};
        var promises = [];
        if (form_types.includes('A6')) {
            form_types.splice(form_types.indexOf('A6'), 1);
            promises.push(Formio.createForm(document.getElementById("formio-silent-placeholder"), form_list.A6[0]).then(function (form) {
                form.submission = form_list;
                form.setPristine(false);
                FormioUtils.eachComponent(form.components, function (c) {
                    if (error_messages['A6']) return;
                    if (c.type === "hidden") return;
                    if (!c.conditionallyVisible(form_list.data) || (c.parent && !c.parent.conditionallyVisible(form_list.data))) return;
                    if (c.type === "file") {
                        c.setValue(form_list.data[c.key]);
                    }
                    if (c.isValid(form_list.data, true)) return;
                    var label = $translate.instant("parta-send-tab-2-errors-text-3");
                    // We want better default than this, but also cannot have it as plain default cause it is used elsewhere with default
                    if (label === "parta-send-tab-2-errors-text-3") {
                        label = "Attach all required documents";
                    }
                    error_messages['A6'] = [{ label: label }];
                });
            }));
        }
        var isSubmittableError = false;
        return $q.all(promises).then(function () {
            angular.forEach(form_types, function(form_type) {
                if (form_list.data.isSubmittable != null && !form_list.data.isSubmittable && !isSubmittableError) {
                    if (!error_messages[form_type]) {
                        error_messages[form_type] = [];
                    }
                    error_messages[form_type].push({ label: $translate.instant("Please add all the missing information") });
                    isSubmittableError = true;
                }
                if (form_type === 'PAYMENT' && form_list.data && !form_list.data.isPaymentPartACompleted && !opts.ignoreIsPaymentPartACompleted) {
                    if (!error_messages[form_type]) {
                        error_messages[form_type] = [];
                    }
                    error_messages[form_type].push({ label: $translate.instant("You have to fill payment details in order to continue") });
                }
                var formioForm = $element.find('#form-type-' + form_type + ' [name="formioForm"] .form-group');
                var errs = [];
                if (formioForm) {
                    var scp = angular.element(formioForm).scope();
                    if (scp) {
                        formioForm = scp.formioForm;
                        if (formioForm) {
                            errs = errs.concat(self._getFormErrors(formioForm));
                        }
                    }
                }

                _.uniq(errs).forEach(function (errComponent) {
                    //TODO: this should not be needed at all, error messages could be plain array, leaving this for now
                    self._addErrorMessage(error_keys, error_messages, form_type, errComponent.key, errComponent.key);
                });
            });

            if(!$.isEmptyObject(error_messages)) {
                has_errors = true;
            }

            if (has_errors && show_notifications) {
                $scope.error_messages_dict = error_messages;
                $scope.error_messages = [];
                angular.forEach(error_messages, function (message) {
                    angular.forEach(message, function (msg) {
                        $scope.error_messages.push(msg);
                    });
                });
                Notification.error({templateUrl: "error_messages.html", scope: $scope,
                            positionY: 'bottom', positionX: 'right', replaceMessage: true});
            }
            return has_errors;
        });
    };

    this.checkFormErrors2 = function(formTypes, $element, $scope, loadedForms, show_notifications){
        var error_keys = self.mapFormErrorLabels(formTypes, loadedForms);
        var show_notifications = show_notifications !== undefined ? show_notifications : true;
        var has_errors = false;
        var error_messages = {};
        angular.forEach(formTypes, function(formType) {
            var comps = FormioUtils.flattenComponents(loadedForms[formType]);

            var formScope = $element.find('form[name='+formType+']').scope();
            Object.keys(comps).forEach(function(keyName) {
                console.log('Comp', formType, keyName);
                if (formScope[formType][keyName])
                {
                    if (formScope[formType][keyName].$invalid)
                    {
                        var errComponent = comps[keyName];
                        self._addErrorMessage(error_keys, error_messages, formType, errComponent.key, errComponent.key);
                    }
                }
            });

            console.log('Validation debug');
            console.log(formScope);
            console.log(comps);
            console.log(error_messages);

        });

        if(!$.isEmptyObject(error_messages)) {
            has_errors = true;
        }

        if (has_errors && show_notifications) {
            $scope.error_messages_dict = error_messages;
            $scope.error_messages = [];
            angular.forEach(error_messages, function (message) {
                angular.forEach(message, function (msg) {
                    $scope.error_messages.push(msg);
                });
            });
            Notification.error({templateUrl: "error_messages.html", scope: $scope,
                        positionY: 'bottom', positionX: 'right', replaceMessage: true});
        }
        return has_errors;
    }


    this.prepareForms = function(forms, type){
        var guide = {
            'components': [],
            'title': 'Guide',
            'type': type //'tab' or 'panel'
        };
        angular.forEach(['A1', 'A2', 'A3', 'A4'], function (form_name) {
            var form = angular.copy(forms[form_name]);
            var second_panel = {
                'components': form.components,
                'title': form.title,
                'type': 'panel'
            };

            // the calculateValue-s might corrupt the data
            if(form_name == 'A2') {
                angular.forEach(form.components, function(comp) {
                    if("calculateValue" in comp) {
                        comp.calculateValue = "";
                    }
                });
            }
            guide.components.push(second_panel);
        });

        if (forms['A5'][0]) {
            var turtle_form = [{
                'components': angular.copy(forms['A5'][0].components),
                'type': 'form',
                'display': 'form'
            }];
        } else {
            var turtle_form = [{
                'components': angular.copy(forms['A5'].components),
                'type': 'form',
                'display': 'form'
            }];
        }
        turtle_form[0].components.unshift(guide);

        var tracker = 1;
        angular.forEach(turtle_form[0].components, function (form) {
            if (type == 'tab') { form.type = 'tab' }
            form.tracker = tracker++;
        });

        return turtle_form;
    };

    this.prepareDocuments = function(document_list, file, data, doc_type){
        var organized_list = {};
        angular.forEach(document_list, function (doc) {
            var doc_id;
            if (doc.document_id) {
                doc_id = doc.document_id;
            } else {
                doc_id = doc.file_name;
            }
            if(doc_type=='certificate') {
                doc.file_url = Backend.backend_url + 'certificates/' + doc.id;
            } else {
                if (doc_type == 'process_certificate') {
                    doc.file_url = Backend.backend_url + 'process/' + file.id + '/certificate/' + doc_id;
                } else {
                    doc.file_url = Backend.backend_url + 'process/' + file.id + '/' + doc_type + '/' + doc_id;
                }
            }

            if (doc.document_name in organized_list) {
                organized_list[doc.document_name]['list'].push(doc);
                if (!doc.is_valid) {
                    organized_list[doc.document_name]['is_valid'] = doc.is_valid;
                }
                organized_list[doc.document_name]['is_validated_by_revision'] = doc.is_validated_by_revision;
            } else {
                organized_list[doc.document_name] = {'is_valid': doc.is_valid, 'is_validated_by_revision': doc.is_validated_by_revision, 'list': [doc]};
            }
        });
        return organized_list;
    };

    this.fileStates = {
        draft: $translate.instant('Draft'),
        closed: $translate.instant('Processed'),
        to_correct: $translate.instant('To correct'),
        pending: $translate.instant('Pending'),
        rejected: $translate.instant('Rejected'),
        applicantPayment: $translate.instant('Applicant Payment'),
        action_required: $translate.instant('Action required')
    }

    this.setFileState = function (file) {
        if (file.filterStatus) {
            // for part B
            file.processStatus = file.filterStatus;
        }
        if (!file.processStatus && file.state) {
            file.processStatus = file.state;
        }
        if (!file.processStatus && file.file_state_code) {
            file.processStatus = file.file_state_code;
        }
        switch (file.processStatus) {
            case 'IN_PROCESS':
            case 'filepending':
                file.file_state = self.fileStates.pending;
                file.labelCssClass = 'label-warning';
                file.rowCssClass = 'info';
                file.file_state_code = 'pending';
                break;
            case 'COMPLETED':
            case 'filevalidated':
                file.file_state = self.fileStates.closed;
                file.labelCssClass = 'label-success';
                file.rowCssClass = 'success';
                file.file_state_code = 'closed';
                break;
            case 'filedecline':
                file.file_state = self.fileStates.to_correct;
                file.labelCssClass = 'label-info';
                file.rowCssClass = 'warning';
                file.file_state_code = 'to_correct';
                break;
            case 'filereject':
            case 'cancelled':
                file.file_state = self.fileStates.rejected;
                file.labelCssClass = 'label-danger';
                file.rowCssClass = 'danger';
                file.file_state_code = 'rejected';
                break;
            case 'applicantPayment':
                file.file_state = self.fileStates.applicantPayment;
                file.labelCssClass = 'label-warning';
                file.rowCssClass = 'warning';
                file.file_state_code = 'applicantPayment';
                break;
            default:
                file.file_state = self.fileStates.draft; //TODO: this should not be needed once, files list and process list are split
                file.labelCssClass = 'label-default';
                file.rowCssClass = 'default';
                file.file_state_code = 'draft';
                break;
        }
    }


    this.setFileApplicantListingProcessParams = function(file) {
        self.setFileState(file);
        var latestTask = self.getLatestTask(file);
        // Below is applicant partb role case
        if (latestTask && latestTask.status === 'filepending' && latestTask.name !== 'applicant' && latestTask.assignee === 'applicant') {
            file.file_state = self.fileStates.action_required;
            file.labelCssClass = 'label-warning';
            file.rowCssClass = 'warning'
            file.file_state_code = 'action_required';
        }

		file.startDate = file.submitted_at || file.startDate;
		file.fileCreatedAt = file.created_at || file.fileCreatedAt;
        file.name = file.service_name;
        if(file.meta_data) {
            file.metaData = file.meta_data;
        }
    };

    this.fileListCount = function(obj) {
        var count = 0;
        angular.forEach(obj, function (o1) {
            count += o1.list.length;
        });
        return count;
    };

    this.detectProcessId = function (param) {
        var param_value = $location.search()[param];
        if ( param_value && param_value.length > 3) {
            return param_value.slice(0, 36);
        }
    };

    this.parseCamundaProperties = function (task_list) {
        // search for first filepending
        var i, d, role_name, role_type, role_form_properties;

        for (var i in task_list) {
            d = task_list[i];
            if (d["status"] == "filepending") {
                role_name = d["name"];
                role_type = d['assignee'];
                if (d['formProperties'] && d['formProperties']['formProperties']) {
                    role_form_properties = d['formProperties']['formProperties'];
                }
            }
        }
        return {'role_form_properties': role_form_properties, 'role_type': role_type, 'role_name': role_name};
    };

    //expects dd:dd:dd
    this.formatDuration = function (duration) {
        if (!duration) return;
        var parsedDuration = duration.split(":");
        var days = "";
        var hours = "";
        var minutes = "";
        if (parsedDuration[0]) {
            days = parsedDuration[0] + ' ' + $translate.instant('d') + ' ';
        }
        if (parsedDuration[1]) {
            hours = parsedDuration[1] + ' ' + $translate.instant('h') + ' ';
        }

        if (parsedDuration[2]) {
            minutes = parsedDuration[2] + ' ' + $translate.instant('m') + ' ';
        }

        return days + hours + minutes;
    }


    this.getFileHistory = function (file/*, context  */) {
        var context = Object(arguments[1]);
        var tasks = [];
        var deposit = {
            'status': 'filevalidated',
            'formProperties': {},
            'owner': file.startedBy ? file.startedBy : 'applicant',
            'ownerFullName': file.startedBy ? file.startedByFullName : 'applicant',
            'endTime': file.startDate,
            'name': 'applicant',
            'id': '',
            'assignee': 'applicant',
            'shortname': $translate.instant('Deposit'),
            'startTime': file.startDate,
            'visibleForApplicant': true,
            statusDescription: $translate.instant("Submitted")
        };
        tasks.push(deposit);
        file.tasks.forEach(function (task) {
            if (context.isApplicant && !task.visibleForApplicant) return;
            if (task.status == null) return;
            if (!task.startTime) return;
            tasks.push(task);
        });


        var history = [];
        if (tasks) {
            history = tasks.sort(self.sorters('startTime', 'date'));
        }
        return history;
    };

    this.getFormPercantage = function (form, data) {
        var stats = {'all': 0, 'missing': 0};

        if (form) {
            stats = self.getLevelStatistics(form.components, data);
        }
        return stats;
    };

    this.evalCanShow = function (customConditional, component, data) {
        if (!customConditional) return true;
        var result = 'true';
        try {
            var row = data; // the customConditional might use row variable
            var script = '(function() { var show = true;';
            script += customConditional.toString();
            script += '; return show; })()';
            var res = eval(script);
            result = (res == null ? 'false' : res);
        } catch (e) {
            console.warn('An error occurred in a custom conditional statement: ' + customConditional, e);
            result = 'false';
        }

        return result.toString() === 'true';
    };

    this.isComponentVisible = function (component, data) {
        var result = true;
        if (!component) {
            console.warn("Not a component passed: ", component);
            return false;
        }
        if (component.hasOwnProperty('customConditional') && component.customConditional) {
            result = self.evalCanShow(component.customConditional, component, data);
        } else if (component.hasOwnProperty('conditional') && component.conditional) {
            try {
                // Some elements use row to do validation. Somehow we need to support it
                result = jsonLogic.apply(Object.assign({}, component.conditional.json), { data: Object.assign({}, data), row: Object.assign({}, data) });
            } catch (e) {
                console.error("unable to parse jsonLogic for compoent: ", component);
            }
        }

        return result;
    }

    //ignoreVisibility - Since formio does not propagate visibility down the tree, we need to enforce it for children
    this.propagateResetHidden = function (components, data, ignoreVisibility) {
        // ARE we 100% sure, that we absolutely need it? What are the use-cases?
        var nestedCollectionNames = ["components", "rows", "columns"], component, isLeaf = true;
        if (!components) return;
        if (!data) return;
        if (Array.isArray(components)) {
            components.forEach(function (component) {
                self.propagateResetHidden(component, data, ignoreVisibility || !self.isComponentVisible(component, data));
            });
        } else {
            component = components;
            nestedCollectionNames.forEach(function (collectionName) { //perhaps we can use some instead... can component have more than one nested collection?
                if (component.hasOwnProperty(collectionName) && Array.isArray(component[collectionName])) {
                    isLeaf = false;
                    component[collectionName].forEach(function (subComponent) {
                        self.propagateResetHidden(subComponent, data, ignoreVisibility || !self.isComponentVisible(component, data));
                    });
                }
            });
            if (!isLeaf) return;
            if (!ignoreVisibility && self.isComponentVisible(component, data)) return;
            if (!data.hasOwnProperty(component.key)) return;
            if (!component.hasOwnProperty('clearOnHide')) return;
            if (component.clearOnHide.toString() !== 'true') return;
            delete data[component.key];
        }
    }

    this.getLevelStatistics = function(components, data) {
        var stats = {'all': 0, 'missing': 0}, s;

        angular.forEach(components, function(component) {
            var result;
            // determine, if it is visible
            if (!self.isComponentVisible(component, data)) return;

            if (component.key && data[component.key] && component.tree ) {
                angular.forEach(data[component.key], function (d) {
                    s = self.getLevelStatistics([component], data[component.key]);
                    stats.all += s.all;
                    stats.missing += s.missing;
                })
            }

            if ("components" in component) {
                s = self.getLevelStatistics(component.components, data);
                stats.all += s.all;
                stats.missing += s.missing;
            }
            if ("rows" in component) {
                s = self.getLevelStatistics(component.rows, data);
                stats.all += s.all;
                stats.missing += s.missing;
            }
            if ("columns" in component) {
                s = self.getLevelStatistics(component.columns, data);
                stats.all += s.all;
                stats.missing += s.missing;
            }

            if (!component.validate || !component.validate.required || !component.key) {
                return;
            }

            stats['all'] += 1;
            if (!data.hasOwnProperty(component.key) || !data[component.key] || (Array.isArray(data[component.key]) && !data[component.key].length)) {
                stats['missing'] += 1;
            }
        });

        return stats;
    };

    this.documentDownload = function(document) {
        $http.get(document.file_url || document.url, {responseType: 'arraybuffer'}).then(self.placeFilePromiseCallback);
    };

    this.getFileForDisplay = function (document/*, opts */) {
        var opts = Object(arguments[1]);
        var urlFieldName = opts.urlFieldName || 'url';
        if (!document || !document[urlFieldName]) return $q.when();
        $sce.trustAsResourceUrl(document[urlFieldName]);
        return $http.get(document[urlFieldName], { responseType: 'arraybuffer' }).then(function (data, status, headers) {
            headers = data.headers();
            var contentType = headers['content-type'] || 'application/octet-stream';
            var urlCreator = window.URL || window.webkitURL || window.mozURL || window.msURL;
            var file = new Blob([data.data], { type: contentType });
            document[urlFieldName] = $sce.trustAsResourceUrl(urlCreator.createObjectURL(file));
        });
    }

    this.getFormioComponentByTag = function (form, tag) {
        var result = null;
        if (!form || !form.components || !tag) return;
        FormioUtils.eachComponent(form.components, function (c) {
            if (c.tags && Array.isArray(c.tags)) {
                return c.tags.some(function (t) {
                    if (t === tag) {
                        result = c;
                        return true;
                    }
                });
            }
        });

        return result;
    }


    // Based on an implementation here: web.student.tuwien.ac.at/~e0427417/jsdownload.html
    this.placeFilePromiseCallback = function(data, status, headers) {

        var octetStreamMime = 'application/octet-stream';
        var success = false;

        // Get the headers
        headers = data.headers();

        // Get the filename from the x-filename header or default to "download.bin"
        var filename = headers['x-filename'] || 'download.bin';

        // Determine the content type from the header or default to "application/octet-stream"
        var contentType = headers['content-type'] || octetStreamMime;

        try
        {
            // Try using msSaveBlob if supported
            console.log("Trying saveBlob method ...");
            var blob = new Blob([data.data], { type: contentType });
            if(navigator.msSaveBlob)
                navigator.msSaveBlob(blob, filename);
            else {
                // Try using other saveBlob implementations, if available
                var saveBlob = navigator.webkitSaveBlob || navigator.mozSaveBlob || navigator.saveBlob;
                if(saveBlob === undefined) throw "Not supported";
                saveBlob(blob, filename);
            }
            console.log("saveBlob succeeded");
            success = true;
        } catch(ex)
        {
            console.log("saveBlob method failed with the following exception:");
            console.log(ex);
        }

        if(!success)
        {
            // Get the blob url creator
            var urlCreator = window.URL || window.webkitURL || window.mozURL || window.msURL;
            if(urlCreator)
            {
                // Try to use a download link
                var link = document.createElement('a');
                if('download' in link)
                {
                    // Try to simulate a click
                    try
                    {
                        // Prepare a blob URL
                        console.log("Trying download link method with simulated click ...");
                        var blob = new Blob([data.data], { type: contentType });
                        var url = urlCreator.createObjectURL(blob);
                        link.setAttribute('href', url);

                        // Set the download attribute (Supported in Chrome 14+ / Firefox 20+)
                        link.setAttribute("download", filename);

                        // Simulate clicking the download link
                        var event = document.createEvent('MouseEvents');
                        event.initMouseEvent('click', true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null);
                        link.dispatchEvent(event);
                        console.log("Download link method with simulated click succeeded");
                        success = true;

                    } catch(ex) {
                        console.log("Download link method with simulated click failed with the following exception:");
                        console.log(ex);
                    }
                }

                if(!success)
                {
                    // Fallback to window.location method
                    try
                    {
                        // Prepare a blob URL
                        // Use application/octet-stream when using window.location to force download
                        console.log("Trying download link method with window.location ...");
                        var blob = new Blob([data], { type: octetStreamMime });
                        var url = urlCreator.createObjectURL(blob);
                        window.location = url;
                        console.log("Download link method with window.location succeeded");
                        success = true;
                    } catch(ex) {
                        console.log("Download link method with window.location failed with the following exception:");
                        console.log(ex);
                    }
                }

            }
        }

        if(!success)
        {
            // Fallback to window.open method
            console.log("No methods worked for saving the arraybuffer, using last resort window.open");
            window.open(httpPath, '_blank', '');
        }
    };

    this.openFileDetail = function(file) {
        var latestTask = self.getLatestTask(file);
        var isInApplicantRole = (latestTask && latestTask.status === 'filepending' && latestTask.name !== 'applicant' && latestTask.assignee === 'applicant');

        if (file.file_state_code == 'draft') {
            window.location.href = '/redirect-to-file/'+file.service_id+'/'+file.file_id;
        } else if (file.file_state_code === 'to_correct' || file.file_state_code === 'applicantPayment' || isInApplicantRole) {
            var processId = file.id;
            if (!processId) {
                processId = file.process_instance_id;
            }
            var serviceId = file.serviceId;
            if (!serviceId) {
                serviceId = file.service_id;
            }
            window.location.href = '/redirect-to-process/'+serviceId+'/'+processId;
        } else {
            /* set url params */
            var process_id = file.id;
            if (!process_id) {
                process_id = file.process_instance_id;
            }
            window.location.href = '/redirect-to-name/application_details/?process_id='+process_id;
        }
    };

    this.getLatestTask = function (file) {
        var latestTask = null;
        if (file.tasks && file.tasks.length) {
            latestTask = file.tasks.slice(-1)[0];
        }

        return latestTask;
    }

    this.printAnyForm = function (data) {
        Backend.postFormOverviewData({
            data: data.data,
            formId: data.formId,
            serviceId: data.service_id,
            schema: data.schema
        }, { version: "3" }).then(function (res) {
            var url = location.origin + '/form-preview-pdf/' + res.data.code;
            var win = window.open(url,'_blank');
            win.focus();
        });
    }

    this.getUriSearchParamValue = function (paramName) {
        var result = null;

        location.search.replace(/^\?/, "").split("&").map(function (p) { return p.split("="); }).some(function (keyValue) {
            if (keyValue.length !== 2) return; //malformed
            if (keyValue[0] === paramName) {
                result = keyValue[1];
                return true;
            }
        });

        return result;
    }

    this.initTippy = function () {
        tippy('[data-tippy-content]');
    }

    this.getRejectionReasons = function (data) {
        var message = null;
        var messages = [];
        var keys = Object.keys(data);
        var rejectionReasons = [];
        var titleKey = null;
        var sufix = '';
        keys.forEach(function (k) {
            if (k.startsWith('FORMDATAREJECTIONREASON') || k.startsWith('sendbacktocorrections') || k.startsWith('rejection')) {
                rejectionReasons.push("- " + data[k]);
            }
            if (!k.startsWith('documentrejection')) {
                return;
            }
            message = null;
            sufix = k.slice('documentrejection'.length);
            titleKey = 'filetitle' + sufix;
            keys.some(function (innerKey) {
                if (innerKey === titleKey) {
                    message = "- " + data[innerKey];
                    return true;
                }
            });
            if (message) {
                message += ": " + data[k];
                messages.push(message);
            }
        });
        rejectionReasons = rejectionReasons.concat(messages);

        return rejectionReasons;
    }

    this.getCasTaggedFormioComponents = function (form) {
        return this.getPrefixTaggedFormioComponents(form, "cas_");
    }

    this.getPrefillTaggedFormioComponents = function (form) {
        return this.getPrefixTaggedFormioComponents(form, "prefill_register_");
    }

    this.getPrefixTaggedFormioComponents = function (form, tag) {
        var taggedComponents = [];
        if (!form || !form.components) return [];
        FormioUtils.eachComponent(form.components, function (c) {
            if (c.tags && Array.isArray(c.tags)) {
                c.tags.some(function (t) {
                    if (t.startsWith(tag)) {
                        taggedComponents.push(c);
                        return true;
                    }
                });
            }
        });

        return taggedComponents;
    }
}]);
