import * as _ from "lodash";

/**
 * Controller for displaying File lists for different roles, including 'applicant'
 */
app.filter('filterBy', function () {
  return function (items, word) {
    var filtered = [];
    var wordMatch = new RegExp(word, 'i');
    angular.forEach(items, function (item) {
        /**
         * make sure the correct property is matched
         */
        if (wordMatch.test(item.meta_data)) {
            filtered.push(item);
        }
    });
    return filtered;
  };
}).controller('InspectorController', ['$scope', '$rootScope', 'Backend', 'SiteConfiguration', '$location', 'Helpers', '$element', '$q', '$http', '$window', 'cfg', '$translate', '$translatePartialLoader', '$sce',
    function($scope, $rootScope, Backend, SiteConfiguration, $location, Helpers, $element, $q, $http, $window, cfg, $translate, $translatePartialLoader, $sce) {
    this.applicant_files = {'list': [], 'count': 0, 'page': 0};
    this.filter_by = ''; // freetext filter
    this.filter = {status: "filepending"}; // filter by field values
    this.order_by = 'startDate';
    this.reverse = true;
    this.GLOBALS = Helpers.GLOBALS;
    var self = this;

    
    this.initController = function (size) {
        self.size = size;
        self.loaded_services = cfg.services;
        self.filter.service = self.loaded_services[0].service_id;
        self.loadProcesses();
    };

    this.chooseService = function() {
        var service_id = self.getUriParamValue('service');

        /* service is set by select */
        if (self.service != null) { service_id = self.service.service_id; };

        self.setUriParam('service', 'service_id', service_id, cfg.services, true);

        
        self.loadProcesses();
    };

    this.setUriParam = function (scope_param, scope_param_value, value, default_list, add_to_uri) {
        if (value == null) return;
        else {
            angular.forEach(default_list, function (s) {
                if (value == s[scope_param_value]) { self[scope_param] = s; }
            });
        }
        if (self[scope_param] && add_to_uri) {
            /*set selected service as url param*/
            var val = self[scope_param][scope_param_value];
            $location.search(scope_param, val);
        }
    };

    this.getUriParamValue = function (key) {
        var return_value = null;
        var params = $location.search();
        if (params[key] !== undefined && params[key].length) {
            return_value = params[key];
        }
        return return_value;
    };
    /**
     * @param opts - options { params: {} }
     * opts.params url params to be passed
     */
    this.loadProcesses = function (/* opts */) {
        var params = self.getUrlParams(), opts = Object(arguments[0]);
        if (opts.params && typeof opts.params === 'object') Object.assign(params, opts.params);
        Backend.getFileListInProcessPage(null, null, params).then(function (ret) {
            angular.forEach(ret['list'], function (f) {
                Helpers.setFileState(f);
                self.loaded_services.some(function (service) {
                    if (service.service_id === f.serviceId) {
                        f.service_name = $translate.instant(service.name);
                        f.service_icon_url = service.get_icon_url;

                        return true;
                    }
                });
            });
            self.applicant_files = ret;
            self.filter.role_name = ret['role'];
        });
    }

    this.collectFilters = function () {
        var filters = {};
        if (self.filter.status && self.filter.status !== 'all') {
            filters.status = self.filter.status;
        }
        
        filters.serviceId = self.filter.service;
        if (self.filter_by.length) {
            filters.metaData = self.filter_by;
        }

        return Object.keys(filters).length ? filters : null;
    };

    this.getUrlParams = function () {
        var filters = self.collectFilters();
        var params = {
            page: 0,
            size: self.size,
            orderBy: self.order_by,
            sortOrder: self.reverse ? "desc" : "asc"
        };
        if (filters) {
            Object.assign(params, filters);
        }

        return params;
    };

    this.pageChanged = _.debounce(function (new_page) {
        self.loadProcesses({ params: { page: new_page ? new_page - 1 : 0 } });
    }, 400);

    this.sortFilesBy = function(value, data_type) {
        self.reverse = (value !== null && self.order_by === value) ? !self.reverse : false;
        self.order_by = value;
		self.loadProcesses();
    };

    this.showFileOverview = function(file) {
        if (file.file_state == 'Draft') {
            window.location.href = '/redirect-to-file/'+file.service_id+'/'+file.file_id+'?tab=inspector';
        }
        window.location.href = '/redirect-to-name/application_details/?process_id='+file.id+'&tab=inspector';
         
    };
}]);