import {Utils as DirectFormioUtils} from 'formiojs-latest';

const getPrefixTaggedFormioComponents = function (form, tag) {
    var taggedComponents = [];
    var searchComponents = (form && form.schema && form.schema.components) || (form && form.components);
    if (!searchComponents) return [];
    DirectFormioUtils.eachComponent(searchComponents, function (c) {
        if (c.tags && Array.isArray(c.tags)) {
            c.tags.some(function (t) {
                if (t.startsWith(tag)) {
                    taggedComponents.push(c);
                    return true;
                }
            });
        }
    }, true);

    return taggedComponents;
}

export default {
    getCasTaggedFormioComponents: function (form) {
        return getPrefixTaggedFormioComponents(form, "cas_");
    },
    getPrefillTaggedFormioComponents: function (form) {
        return getPrefixTaggedFormioComponents(form, "prefill_register_");
    },
    replaceWithEvaluate: function (content, data) {
        return content.replace(/{{(.*?)}}/, function (match, p1) {
            let value = eval(p1);
            if (value === undefined || value === null) {
                value = "";
            }
            return String(value);
        });
    },
    // I did not find cleaner, programmatical way to enable jumping to WIZARD form page
    dispatchPageFromHash: function (form) {
        const formRuntimeConfig = {};
        Object.keys(form.refs).some((key) => {
            if (key.slice(-4) === 'link') {
                formRuntimeConfig.pageLinks = Array.from(form.refs[key]);
                return true;
            }
        });

        if (location.hash) {
            let pageNum = null;
            const pageTitle = location.hash.replace(/#(.+)/, "$1");
            if (form.pages) {
                form.pages.some((page, idx) => {
                    if (page.component && page.component.title === pageTitle) {
                        pageNum = idx + 1;
                        return true;
                    }
                });
            }
            if (pageNum == null) {
                pageNum = Number(location.hash.replace(/.*page(\d).*/, "$1"));
            }
            if (pageNum && formRuntimeConfig.pageLinks.length >= pageNum) {
                formRuntimeConfig.pageLinks[pageNum - 1].click();
            }
        }
    }
}
