app.controller('BusinessEntityListController', ['$scope', '$http', '$window', 'Backend', 'Helpers', 'cfg', '$translate', '$compile',
    function($scope, $http, $window, Backend, Helpers, cfg, $translate, $compile) {
        

        var self = this;
        self.reverse = true;
        self.be_list = [];
        self.total_bes = 0;
        self.current_be_ordering = '-created_at';
        this.current_be_page = 1;
        self.areBELoaded = false;
        self.originalName = null;
        self.nuName = null;
        self.currentlyEdited = null;

      
        this.init = function() {
            GLOBALS.getConfirmationModalBody = function (result) {
                return $compile('<p class="confirmation-modal-text">This modification is considered <span class="strong">critical</span>. Please confirm the following change:</p><div class="confirmation-modal-change-detail-container"><div class="orig-name"><h6 class="upper-text">Original name</h6><span class="strong">{{ my_business_entities_ctrl.originalName }}</span></div><div><h6 class="upper-text">New name</h6><span class="strong">{{ my_business_entities_ctrl.nuName }}</span></div></div>')($scope);
            }
            GLOBALS.confirmModal = function (result) {
                if (result) {
                    self.updateBEName();
                    return;
                }
                self.cancelNameChange();
            }
            Backend.getBusinessEntities(self.current_be_page, self.current_be_ordering, true).then(self.processApplications);
        };

        this.processApplications = function(data) {
            self.total_bes = data.count;
            self.be_list = data.results.map(function (item) {
                item.isOpened = false;
                item.nameEdition = false;
                item.originalName = item.name;
                item.file_set = [];
                return item;
            });
            self.areBELoaded = true;
        };

        this.cancelNameChange = function() {
            if (!self.currentlyEdited) return;
            self.be_list.forEach(function (be) {
                be.nameEdition = false;
                be.name = be.originalName;
            });
            self.currentlyEdited = null;
            if(!$scope.$$phase) { // this hack is needed cause we mix regular DOM with angular here
                $scope.$digest();
            }
        }

        this.updateBEName = function() {
            Backend.updateBusinessentity(self.currentlyEdited).then(function (result) {
                self.currentlyEdited.originalName = result.data.name;
                self.currentlyEdited.nameEdition = false;
            });
        } 

        this.sortBes = function (list) {
            if (!list) return list;
            return list.sort(function (a, b) {
                return new Date(b.created_at) - new Date(a.created_at);
            });
        }

        this.onPageChange = function(new_page) {
            self.current_be_page = new_page;
            Backend.getBusinessEntities(new_page, self.current_be_ordering, true).then(self.processApplications);
        };

        this.onFilesPageChange = function(new_page, be) {
            Backend.getUserFiles({ businessEntity: be.business_entity_id, page: new_page }).then(function (result) {
                be.file_set.length = 0;
                result.results.map(function (file) {
                    Helpers.setFileApplicantListingProcessParams(file);
                    be.file_set.push(file);
                });
            });
        }

        this.orderBesBy = function(value) {
            self.reverse = (value !== null && self.current_be_ordering === value) ? !self.reverse : false;
      
            if(self.reverse) {
                self.current_be_ordering = "-"+value;
            } else {
                self.current_be_ordering = value;
            }
            Backend.getBusinessEntities(self.current_be_page, self.current_be_ordering, true).then(self.processApplications);
            
        };
        this.showBusinessRights = function (be) {
            if (be.has_root_access) {
                window.location.href = "/" + CURRENT_LANGUAGE + "/manage-business-entity/" + be.business_entity_id;
            }
        }
        this.openFile = function(file) {
            // ugly to remove one day
            Helpers.setFileState(file);
            return Helpers.openFileDetail(file);
        };

        this.showFileProcessing = function(file) {
            console.log(file);
            if (file.file_state_code == 'draft') {
                window.location.href = '/redirect-to-file/'+file.service_id+'/'+file.file_id;
            }
            else if (file.file_state_code === 'to_correct' || file.file_state_code === 'applicantPayment') {
                var processId = file.id;
                if (!processId) {
                    processId = file.process_instance_id;
                }
                var serviceId = file.serviceId;
                if (!serviceId) {
                    serviceId = file.service_id;
                }
                window.location.href = '/redirect-to-process/'+serviceId+'/'+processId;
            } else {
                /* set url params */
                var process_id = file.id;
                if (!process_id) {
                    process_id = file.process_instance_id;
                }
                window.location.href = '/redirect-to-name/application_details/?process_id='+process_id;
                //$location.search({file_id: file.file_id});
            }
        };
        this.toggleBEOpen = function (be) {
            be.isOpened = !be.isOpened;
            if (!be.isOpened) return;
            self.be_list.forEach(function (business_entity) {
                if (business_entity.business_entity_id !== be.business_entity_id) {
                    business_entity.isOpened = false;
                }
            });
            Backend.getUserFiles({ businessEntity: be.business_entity_id }).then(function (result) {
                be.file_set.length = 0;
                result.results.map(function (file) {
                    Helpers.setFileApplicantListingProcessParams(file);
                    be.file_set.push(file);
                });
            });
        }
}]);
